import React from 'react';
import { Button, Flex, Typography, Form, Col, Row } from 'antd';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';
import { ALL_PRIMARY_KPI_KEYS } from 'src/components/project/hooks/usePrimaryKpis';
import useRenderKpiRow from 'src/hooks/project/useRenderKpiRow';
import PropTypes from 'prop-types';
import { SUPPLIER_CONFIGURATION_STEPS } from 'src/constants';
import styles from './TestingStep.module.less';
import BuyerTestingSetupLayout from './BuyerTestingSetupLayout';

export default function TestingStep({ project, onChangeStep, testingData }) {
  const [form] = Form.useForm();
  const { renderKpiRow, mappedKpiCodeMetadata, mappedKpiIdMetadata } =
    useRenderKpiRow('uuid');

  const kpiKeys = Object.keys(
    (project?.digital_rfq_filter ?? project?.filters)?.kpis ?? {}
  );

  const secondaryKpis = kpiKeys
    .filter((kpi) => !ALL_PRIMARY_KPI_KEYS.includes(kpi))
    .sort((a, b) => a.localeCompare(b));

  const primaryKpis = kpiKeys
    .filter((kpi) => ALL_PRIMARY_KPI_KEYS.includes(kpi))
    .sort((a, b) => a.localeCompare(b));

  const handleClickNext = async () => {
    const values = await form.validateFields();
    if (values?.volume) {
      const currentKpis = Object.keys(values?.digital_rfq_filter?.kpis ?? {});
      const numericalPropertyTests = currentKpis.map((kpiCode) => {
        const kpiData = values?.digital_rfq_filter?.kpis[kpiCode];
        const kpiMetadata = mappedKpiCodeMetadata.get(kpiCode);
        return {
          property_id: kpiMetadata.uuid,
          min_expected: kpiData.min ?? null,
          max_expected: kpiData.max ?? null,
          units: kpiData.units ?? null,
          test_standards: kpiData.methods ?? null
        };
      });
      onChangeStep(SUPPLIER_CONFIGURATION_STEPS.COMPLIANCE, {
        volume: values.volume,
        volume_units: values.volume_units,
        numerical_property_tests: numericalPropertyTests
      });
    }
  };

  const getDigitalRfqDefaultValues = () => {
    if (!testingData?.numerical_property_tests)
      return project?.digital_rfq_filter ?? {};
    const kpis = {};
    testingData.numerical_property_tests.forEach((test) => {
      const kpiCode = mappedKpiIdMetadata.get(test.property_id)?.code;
      const projectKpiData = project?.digital_rfq_filter?.kpis[kpiCode];
      kpis[kpiCode] = {
        ...projectKpiData,
        min: test.min_expected,
        max: test.max_expected,
        units: test.units,
        methods: test.test_standards
      };
    });
    return { kpis };
  };

  return (
    <BuyerTestingSetupLayout>
      <Flex className="w-full mt-60" align="center" justify="space-between">
        <div>
          <Typography.Title level={3}>Test Setup</Typography.Title>
          <Typography.Text type="secondary">
            Add testing requirements that match your material needs
          </Typography.Text>
        </div>
        <Flex className="gap">
          <Button onClick={() => onChangeStep(null)}>Back</Button>
          <Button onClick={handleClickNext} type="primary">
            Next
          </Button>
        </Flex>
      </Flex>
      <Typography.Title className="mt-lg" level={4}>
        Testing Volume
      </Typography.Title>
      <Form
        form={form}
        initialValues={{
          digital_rfq_filter: getDigitalRfqDefaultValues(),
          volume: testingData.volume,
          volume_units: testingData.volume_units
        }}
        layout="vertical"
      >
        <Flex className="gap-lg mt-lg" align="center">
          <Form.Item
            rules={[{ required: true }]}
            name="volume"
            className="mb-0"
            label="Lab Test Volume"
          >
            <QuantityWithUnitsInput
              className={styles.quantityInput}
              unitsFieldName="volume_units"
            />
          </Form.Item>
        </Flex>
        {primaryKpis && primaryKpis.length ? (
          <>
            <Typography.Title level={5} className="mt-40">
              Primary Specifications
            </Typography.Title>
            <Typography.Text type="secondary">
              Imported from Explore
            </Typography.Text>
            <Row className="mt-lg">
              <Col span={9}>
                <Typography className={styles.propertyTitle}>
                  Property
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className={styles.propertyTitle}>Min.</Typography>
              </Col>
              <Col span={3}>
                <Typography className={styles.propertyTitle}>Max.</Typography>
              </Col>
              <Col span={3}>
                <Typography className={styles.propertyTitle}>Units.</Typography>
              </Col>
              <Col span={6}>
                <Typography className={styles.propertyTitle}>
                  Testing Method
                </Typography>
              </Col>
            </Row>
            {primaryKpis.map((kpiCode) => renderKpiRow(kpiCode))}
          </>
        ) : null}
        {secondaryKpis && secondaryKpis.length > 0 ? (
          <>
            <Typography.Title level={5} className="mt-40">
              Secondary Specifications
            </Typography.Title>
            <Typography.Text type="secondary">
              Imported from Explore
            </Typography.Text>
            {secondaryKpis.map((kpiCode) => renderKpiRow(kpiCode, true))}
          </>
        ) : null}
      </Form>
    </BuyerTestingSetupLayout>
  );
}

TestingStep.propTypes = {
  project: PropTypes.object,
  onChangeStep: PropTypes.func,
  testingData: PropTypes.object
};
