import { Checkbox, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import {
  REGION_AFRICA,
  REGION_AMERICAS_CENTRAL,
  REGION_AMERICAS_NORTH,
  REGION_AMERICAS_SOUTH,
  REGION_ASIA,
  REGION_EU,
  REGION_OCEANIA,
  REGIONS
} from 'src/pages/insights/dimensions';
import { useFiltersPermissions } from 'src/utils/authentication';

const options = [
  {
    value: REGION_AMERICAS_NORTH,
    label: REGIONS[REGION_AMERICAS_NORTH].label
  }, // 'North America'
  {
    value: REGION_AMERICAS_CENTRAL,
    label: REGIONS[REGION_AMERICAS_CENTRAL].label
  }, // 'Central America'
  {
    value: REGION_AMERICAS_SOUTH,
    label: REGIONS[REGION_AMERICAS_SOUTH].label
  }, // 'South America'
  { value: REGION_EU, label: REGIONS[REGION_EU].label }, // 'Europe'
  { value: REGION_ASIA, label: REGIONS[REGION_ASIA].label }, // 'Asia'
  { value: REGION_AFRICA, label: REGIONS[REGION_AFRICA].label }, // 'Africa'
  { value: REGION_OCEANIA, label: REGIONS[REGION_OCEANIA].label } // 'Oceania
];

export default function RegionFilter() {
  const [regionFilter, setRegionFilter, clearRegionFilter] =
    useConciergeContextState(['explore', 'filters', 'region_code__in']);

  const onRegionFilterValuesChange = (values) => {
    if (!values || values.length === 0) {
      clearRegionFilter();
      return;
    }
    setRegionFilter(values);
  };

  const filters = useFiltersPermissions('region_code__in');
  const isDisabled = (attribute) =>
    filters ? !filters.find((o) => o === attribute) : false;
  const filteredOptions = useMemo(
    () => options.map((o) => ({ ...o, disabled: isDisabled(o.value) })),
    [filters]
  );
  return (
    <>
      <Checkbox
        onChange={(e) =>
          onRegionFilterValuesChange(
            e.target.checked ? options.map((o) => o.value) : []
          )
        }
        indeterminate={
          regionFilter?.length > 0 && regionFilter?.length < options.length
        }
        checked={regionFilter?.length === options.length}
      >
        Select all
      </Checkbox>
      <Divider style={{ margin: '10px 0' }} />
      <Checkbox.Group
        options={filteredOptions}
        value={regionFilter}
        onChange={onRegionFilterValuesChange}
      />
    </>
  );
}
