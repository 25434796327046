/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Switch, message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveEmailTemplate } from 'src/Mutation';

function TemplateEditor({ project, templateKey, locked }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState('');

  const queryClient = useQueryClient();

  const { mutate: mutateSaveEmailTemplate, isLoading: isSaving } = useMutation({
    mutationFn: (updates) =>
      saveEmailTemplate({
        project_uuid: project.uuid,
        template_key: templateKey,
        updates
      }),
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      setContent('');
    },
    onError: (e) => {
      messageApi.error('Error saving template edits');
    }
  });

  // Handle input events for editable elements
  const handleInput = (event) => {
    const key = event.target.getAttribute('data-key');
    const element = event.target;
    // element.innerHTML = event.target.innerHTML.replace(/&nbsp;/g, ' ');
    if (key) {
      setContent((prevContent) => ({
        ...prevContent,
        [key]: event.target.innerHTML
      }));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      // Insert a new line as a line break within the paragraph
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      const br = document.createElement('br');
      range.insertNode(br);

      // Move the cursor after the new line
      range.setStartAfter(br);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = (e.clipboardData || window.clipboardData).getData('text');
    document.execCommand('insertText', false, text); // Insert clean text
  };

  const toggleEdit = () => {
    if (isEditing) setContent('');
    setIsEditing((prev) => !prev);
  };

  const handleSave = () => {
    mutateSaveEmailTemplate(content);
  };

  const template = project.email_templates[templateKey];
  // Add contentEditable="true" to elements with data-key when isEditing is true
  const renderTemplate = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(template, 'text/html');

    doc.querySelectorAll('[data-key]').forEach((el) => {
      if (isEditing) {
        el.setAttribute('contenteditable', 'true');
      } else {
        el.removeAttribute('contenteditable');
      }
    });

    return { __html: doc.body.innerHTML };
  };

  return (
    <div className="wrapper">
      {/* Top-right corner buttons */}
      <div className="edit-controls">
        {locked ? (
          ''
        ) : (
          <>
            {content ? (
              <Button
                type="primary"
                htmlType="button"
                onClick={handleSave}
                disabled={isSaving}
              >
                Save
              </Button>
            ) : (
              ''
            )}
            <Switch
              checked={isEditing}
              onChange={toggleEdit}
              checkedChildren="Edit"
              unCheckedChildren="View"
            />
          </>
        )}
      </div>
      <div
        className="render-zone"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        role="textbox"
        aria-multiline="true"
        tabIndex={0}
        aria-label="Editable text"
        dangerouslySetInnerHTML={renderTemplate()}
        contentEditable={false} // Prevent unintended editing of the container itself
        suppressContentEditableWarning
      />
    </div>
  );
}

TemplateEditor.propTypes = {
  project: PropTypes.object,
  templateKey: PropTypes.string,
  locked: PropTypes.bool
};

export default TemplateEditor;
