import { useMemo } from 'react';

const lastDetailedCountValue = (detailedCounts, key) =>
  detailedCounts.reduce((a, c) => Math.max(c?.[key] || 0, a), 0) || 0;

export default function useDetailedSupplierCountBreakdown(
  detailedCounts,
  resultIndexFilter,
  isExpanded
) {
  return useMemo(() => {
    if (!detailedCounts) return {};
    const { length } = detailedCounts;
    const unverifiedCount = detailedCounts[length - 1];
    const detailedCountsMain =
      length === 1
        ? detailedCounts
        : detailedCounts
            .slice(0, -1)
            .map((c, i) =>
              !resultIndexFilter ||
              resultIndexFilter.length === 0 ||
              resultIndexFilter.includes(i)
                ? c
                : null
            );
    const detailedCountsBase = isExpanded
      ? detailedCountsMain.filter((_, index) => index % 2 === 0)
      : detailedCountsMain;
    const detailedCountsExpanded = isExpanded
      ? detailedCountsMain.filter((_, index) => index % 2 === 1)
      : [];

    const supplierCountBase = lastDetailedCountValue(
      detailedCountsBase,
      'supplier'
    );
    const tdsCountBase = lastDetailedCountValue(detailedCountsBase, 'tds');
    const tdsCountExpanded = lastDetailedCountValue(
      detailedCountsExpanded,
      'tds'
    );
    const supplierCountExpanded = lastDetailedCountValue(
      detailedCountsExpanded,
      'supplier'
    );

    const supplierCountUnverified = unverifiedCount?.supplier;

    const tdsCountUnverified = unverifiedCount?.tds;
    return {
      supplierCountBase,
      supplierCountExpanded,
      supplierCountUnverified,
      tdsCountBase,
      tdsCountExpanded,
      tdsCountUnverified
    };
  }, [detailedCounts]);
}
