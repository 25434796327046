import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Route, Routes, useParams } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import ProcureOverview from 'src/components/project/procure/Overview';
import ProjectProcureWorkflow from 'src/components/project/procure/ProjectProcureWorkflow';
import SingleProjectTds from 'src/components/project/procure/SingleProjectTds';
import BuyerSetupTesting from 'src/pages/project/BuyerSetupTesting';

export default function ProcureRoutes() {
  return (
    <Routes>
      <Route path="/:projectId/*" element={<ProcureSubroutes />} />
    </Routes>
  );
}

function ProcureSubroutes() {
  const { projectId } = useParams();
  const { getProjectbyId } = useSidebarContext();

  const project = getProjectbyId(projectId);

  if (!project || project.uuid !== projectId) return <Skeleton />;

  return (
    <div className="project-source">
      <Row wrap={false} className="individually-scrolling-cols">
        <Col className="project-source--sidebar">
          <ProjectProcureWorkflow projectId={projectId} />
        </Col>
        <Col className="project-source--content">
          <Routes>
            <Route path="/" element={<ProcureOverview />} />
            <Route
              path="/:projectTdsId/setup-testing"
              element={<BuyerSetupTesting project={project} />}
            />
            <Route
              path="/:projectTdsId"
              element={<SingleProjectTds project={project} />}
            />
          </Routes>
        </Col>
      </Row>
    </div>
  );
}
