import { useMemo } from 'react';

import useGetWarehouseTdss, {
  useGetWarehouseTdssKey
} from 'src/Query/useGetWarehouseTdss';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

const ALLOWED_STATUS_LIST = ['procure'];
const EMPTY_DICT = {};

export default function useProcureTdss(projectFiltersOverrides = EMPTY_DICT) {
  const { activeProjectId } = useSidebarContext();
  const filters = useRelevantFiltersForForm();
  const quotesFilters = useMemo(() => {
    const filtersCopy = { ...filters };
    if (filters && activeProjectId) {
      filtersCopy.project = {
        id: activeProjectId,
        use_filters: true,
        status: ALLOWED_STATUS_LIST,
        ...projectFiltersOverrides
      };

      // remove all filters that filter the result set because we are filtering on suppliers/
      // tds in the project
      if (filtersCopy.kpis) {
        Object.keys(filtersCopy.kpis).forEach((code) => {
          filtersCopy.kpis[code] = { allow_null: true };
        });
      }
      if (filtersCopy.properties) {
        Object.keys(filtersCopy.properties).forEach((code) => {
          filtersCopy.properties[code] = [];
        });
      }
      if (filtersCopy.form_code__in) {
        filtersCopy.form_code__in = [];
      }
      if (filtersCopy.type_code__in) {
        filtersCopy.type_code__in = [];
      }
      if (filtersCopy.region_code__in) {
        filtersCopy.region_code__in = [];
      }
      filtersCopy.kpi_operator = 'none';
    }
    return filtersCopy;
  }, [filters, activeProjectId, projectFiltersOverrides]);

  const fields = [
    'tds_id',
    'form',
    'form_code',
    'type',
    'type_code',
    'company_id',
    'company_name',
    'region_code',
    'region',
    'sku',
    'capacity_lbs',
    'supply_capacity_lbs'
  ];

  const agg_fields = ['city', 'state', 'country', 'lat', 'long', 'location_id'];

  const completeFilters = useMemo(
    () => ({
      ...quotesFilters,
      fields,
      agg_fields,
      augment_prices: {}
    }),
    [quotesFilters]
  );

  const queryKey = useGetWarehouseTdssKey(completeFilters);

  const queryObj = useGetWarehouseTdss(completeFilters, {
    refetchOnMount: true
  });

  return { ...queryObj, queryKey };
}
