export const METADATA_PROPERTY_TYPE = {
  CERTIFICATIONS: 'certs',
  COLOR: 'color',
  PROCESSING_METHOD: 'processing-method',
  APPLICATION: 'application',
  SOURCE_TYPE: 'source-type',
  BALE_GRADE: 'bale_grade',
  GRADE: 'grade',
  POLYMER_TYPE: 'polymer-type',
  APPLICATION_TYPE: 'application_type',
  ADDITIVES_FILLERS: 'additive',
  TYPE: 'type',
  BALE_SOURCE: 'bale_source',
  BALE_FORM: 'bale_sform',
  BALE_CONTAMINATION: 'bale_contamination',
  FORM: 'form',
  FLAMMABILITY_RATING: 'flamability',
  INDUSTRY: 'industry'
};

export const PRICING_TERMS_OPTIONS = [
  { value: 'EXW', label: 'Ex-works' },
  { value: 'FOB', label: 'Free on board' },
  { value: 'FCA', label: 'Free carrier' },
  { value: 'CFR', label: 'Cost and freight' },
  { value: 'CPT', label: 'Carriage paid' },
  { value: 'DDP', label: 'Delivered duty paid' },
  { value: 'DPU', label: 'Delivered at place unloaded' },
  { value: 'FAS', label: 'Free alongside ship' },
  { value: 'CIF', label: 'Cost insurance and freight' }
];

export const PROJECT_STAGE = {
  EXPLORE: 'explore',
  SEARCH: 'search',
  PROCURE: 'procure',
  MANAGE: 'manage'
};

export const DEFAULT_PRIMARY_SPEC_CODES = ['MFI', 'D'];
