import { prettyNumberPrice } from 'src/components/utils/prettyNumber';

export const currencySymbolMap = {
  EUR: '€',
  USD: '$',
  USC: '¢',
  JPY: '￥'
};

export const supportCurrencyCodes = ['USD', 'EUR', 'JPY', 'CNY'];

export const currencySymbol = (currency) =>
  (currency && (currencySymbolMap[currency] || `${currency} `)) || '$';

export const currencyValuePrice = (value, currency, units = null) =>
  `${currencySymbol(currency)}${prettyNumberPrice(value)}${
    units ? `\u00A0/${units}` : ''
  }`;

export const formatterInputCurrency = (v) => {
  // Add commas every 3 nums but only before decimal point
  const [wholeNumbers] = v.split('.');
  const wholeNumbersWithCommas = wholeNumbers.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );

  return v.replace(wholeNumbers, wholeNumbersWithCommas);
};

export const parserInputCurrency = (v) => v?.replace(/(,*)/g, '');
