import { Button, Row, Col, Typography, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useParentSize } from '@visx/responsive';

// React PDF
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { useUser } from 'src/utils/authentication';
import PropTypes from 'prop-types';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// eslint-disable-next-line react/display-name
function QuoteDetailStep({ project, children }) {
  const { data: user } = useUser();
  const [numPages, setNumPages] = useState();
  const { parentRef, width } = useParentSize({
    initialSize: { width: 750 }
  });

  return (
    <Row className="digital-rfq--body" wrap={false}>
      <Col lg={16} xxl={18} className="digital-rfq--content show-lg">
        <div className="digital-rfq--document-wrapper" ref={parentRef}>
          <Document
            onLoadSuccess={(data) => setNumPages(data.numPages)}
            file={project?.rfq_file}
            noData={!project ? 'Loading PDF..' : 'No PDF file specified.'}
          >
            {Array(numPages)
              .fill('')
              .map((_, i) => (
                <Page key={i} width={width} pageNumber={i + 1} />
              ))}
          </Document>
        </div>
      </Col>
      <Col xs={24} lg={8} xxl={6} className="digital-rfq--sidebar">
        {!project || !user ? (
          <Skeleton />
        ) : (
          <div className="digital-rfq--sidebar--inner">
            {project?.rfq_file && (
              <a
                className="hide-lg"
                title="View RFQ File"
                href={project?.rfq_file}
                target="_blank"
              >
                <Button
                  block
                  type="primary"
                  htmlType="button"
                  className="mb-lg"
                >
                  View Request
                </Button>
              </a>
            )}
            <Typography.Title level={3}>Quote Details</Typography.Title>
            <Typography.Paragraph type="secondary" className="mb-xxl">
              Upload your TDS and respond to the questions below.
            </Typography.Paragraph>
            {children}
          </div>
        )}
      </Col>
    </Row>
  );
}

QuoteDetailStep.propTypes = {
  project: PropTypes.object,
  children: PropTypes.node
};

export default QuoteDetailStep;
