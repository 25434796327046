import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tooltip
} from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CloseCircleTwoTone, RightOutlined } from '@ant-design/icons';
import MetaPropertyButtonGroup from 'src/components/form/MetaPropertyButtonGroup';
import MetaPropertySelect from 'src/components/form/MetaPropertySelect';
import FormSection from 'src/components/form/FormSection';
import MultipleLocationInput, {
  parseApiLocation,
  parseInternalLocation
} from 'src/components/form/MultipleLocationInput';
import PropertyRangeInput from 'src/components/onboard/PropertyRangeInput';
import SwitchableInput from 'src/components/form/SwitchableInput';
import UploadDocuments from 'src/components/form/UploadDocuments';
import {
  filterOptionByRelationship,
  findProperties,
  findProperty
} from 'src/utils/properties';
import { createOrUpdateTds } from 'src/Mutation';
import useGetTds from 'src/Query/useGetTds';
import { FormControlEmbeddedLabel } from 'src/components/onboard/ReviewFormItem';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { normalizeNumericalProperty } from 'src/components/onboard/MaterialPropertyForm';
import useMetaProperty from 'src/hooks/useMetaProperty';

const requiredProperties = ['form', 'type', 'source-type', 'color'];

export const reshapeForForm = (tds, extraMetaProperties = []) => {
  const values = { ...tds };
  values.type = findProperties(tds, 'type').map((o) => o.uuid);
  values.sourceType = findProperty(tds, 'source-type')?.uuid;
  values.color = findProperties(tds, 'color').map((o) => o.uuid);
  values.form = findProperties(tds, 'form').map((o) => o.uuid);
  values.locations =
    Array.isArray(tds.locations) &&
    tds.locations.map((location) => parseApiLocation(location));
  delete values.material_properties;
  delete values.company;
  delete values.material_numerical_properties;

  values.material_numerical_properties =
    tds.material_numerical_properties.reduce(
      (acc, tdsMnp) => ({
        ...acc,
        [tdsMnp.property.uuid]: normalizeNumericalProperty(tdsMnp)
      }),
      {}
    );
  values.extra_properties = extraMetaProperties.map((mp) =>
    findProperties(tds, mp.code).map((o) => o.uuid)
  );

  return values;
};

const DEFAULT_NP_ORDER = ['IV', 'MFI', 'T'];

/* when we first built this screen, we filtered colors to limit them to what made sense for the type
 * and we were only allowing a single type to be selected.
 * We have change this to allow multiple types, and so the filtering of colors should be the union of all possible
 * combinations of form and type, but for performance we just show all colors when there is more than one type specified
 */

const filterOptionByRelationshipTypeForm = (types, forms) =>
  Array.isArray(types) && types.length === 1
    ? filterOptionByRelationship([...(types || []), ...(forms || [])])
    : () => true;

function TechnicalDataSheet({
  hideModal,
  showCompany,
  showAdminLinks,
  onSuccess,
  tdsId,
  open,
  supplierId,
  canDuplicate
}) {
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const modalTopRef = useRef();

  const [npOrder, setNpOrder] = useState(DEFAULT_NP_ORDER);
  const [showAllKpis, setShowAllKpis] = useState(false);
  const [showAllProperties, setShowAllProperties] = useState(false);

  const queryClient = useQueryClient();

  const { data: activeTds, error: tdsFetchError, isLoading } = useGetTds(tdsId);

  const { mutateAsync: mutateTds, isMutating: tdsUpdating } = useMutation({
    mutationFn: createOrUpdateTds,
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['technical-data-sheets']
      });
      queryClient.invalidateQueries({
        queryKey: ['technicalDataSheet']
      });

      if (onSuccess) {
        const suppressHide = onSuccess(response);
        if (!suppressHide) {
          hideModal();
        }
      } else {
        hideModal();
      }
      setError();
      // modalTopRef.current?.scrollIntoView({ block: 'nearest' });
    },
    onError: (e) => {
      const errorObj = e.detail;

      let errorStr = 'An error ocurred';

      if (typeof errorObj === 'object') {
        Object.keys(errorObj).forEach((fieldName) => {
          let fieldErrorStr = errorObj[fieldName];

          if (typeof fieldErrorStr === 'object')
            fieldErrorStr = JSON.stringify(fieldErrorStr);

          if (form.getFieldValue(fieldName) !== undefined) {
            form.setFields([
              {
                name: fieldName,
                errors: [fieldErrorStr]
              }
            ]);
          }

          errorStr += `; ${fieldName}: ${fieldErrorStr}`;
        });
      }

      setError(errorStr);

      modalTopRef.current?.scrollIntoView({ block: 'nearest' });

      window.console.error('Error submitting TDS', e, errorObj);
    }
  });

  const numericalProperties = useMetaNumericalProperty();
  const metaProperties = useMetaProperty();
  const extraMetaProperties = useMemo(
    () => metaProperties.filter((o) => requiredProperties.indexOf(o.code) < 0),
    [metaProperties]
  );
  const extraMetaPropertyOptions = useMemo(
    () =>
      extraMetaProperties.map((p) =>
        p.options.map((o) => ({ label: o.value, value: o.uuid }))
      ),
    [extraMetaProperties]
  );

  const submitTds = async (createNew) => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();

      const material_properties_ids = [
        formValues.type,
        formValues.form,
        formValues.sourceType,
        formValues.color,
        ...(formValues.extra_properties || [])
      ]
        .flat()
        .filter((tdsMp) => !!tdsMp);

      const material_numerical_properties = Object.values(
        formValues.material_numerical_properties
      ).filter((tdsMnp) => !!tdsMnp);

      const tds = {
        ...(activeTds && !createNew ? { uuid: activeTds.uuid } : {}),
        ...formValues,
        material_properties_ids,
        material_numerical_properties,
        locations:
          (Array.isArray(formValues.locations) &&
            formValues.locations.map((location) =>
              parseInternalLocation(location)
            )) ||
          [],
        extra: {
          // Preserve extra if editing existing TDS
          ...(activeTds?.extra && !createNew ? activeTds.extra : {}),
          // Additional extra data from form
          ...(formValues.extra || {})
        }
      };

      tds.documents_ids = tds.documents?.map((o) => o.uuid);
      if (activeTds) {
        tds.company_id = activeTds.company.uuid;
      } else if (supplierId) {
        tds.company_id = supplierId;
      }
      await mutateTds(tds);
    } catch (ex) {
      // For some reason antd's built-in scrollToFirstError is not
      // working here, so we do that manually
      if (ex?.errorFields?.length) {
        const firstErrorInputId = ex.errorFields[0].name[0];
        document
          .getElementById(firstErrorInputId)
          .closest('.ant-form-item')
          .scrollIntoView({
            behavior: 'smooth'
          });
      }
    }
  };

  // Set numericalProps order to surface populated ones at top
  useEffect(() => {
    if (!activeTds?.uuid) {
      setNpOrder(DEFAULT_NP_ORDER);
      return form.resetFields();
    }

    const tdsMnpCodes = activeTds.material_numerical_properties.map(
      ({ property }) => property.code
    );

    setNpOrder([
      ...tdsMnpCodes,
      ...DEFAULT_NP_ORDER.filter((code) => !tdsMnpCodes.includes(code))
    ]);

    return form.setFieldsValue(reshapeForForm(activeTds, extraMetaProperties));
  }, [activeTds, extraMetaProperties]);

  const materialType = Form.useWatch('type', form);
  const materialForm = Form.useWatch('form', form);

  const optionFilter = useMemo(
    () => filterOptionByRelationshipTypeForm(materialType, materialForm),
    [materialType, materialForm]
  );

  const ready = !(isLoading && tdsId);
  const canEdit = true;

  if (tdsFetchError) {
    window.console.log('TDS fetch error', tdsFetchError);
  }

  return (
    <Modal
      open={open}
      title={
        (showCompany && (
          <>
            {activeTds?.title}&nbsp;&nbsp;
            {showAdminLinks && (
              <a
                target="_blank"
                href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/technicaldatasheet/${activeTds?.uuid}/`}
              >
                <RightOutlined />
              </a>
            )}
          </>
        )) ||
        undefined
      }
      onCancel={hideModal}
      style={{ top: 8, maxHeight: 'calc(100vh - 16px)' }}
      width={1000}
      maskClosable={false}
      closeIcon={<CloseCircleTwoTone />}
      confirmLoading={tdsUpdating}
      className="technical-data-sheet-form-wrap"
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          disabled={!ready || !canEdit}
          type="primary"
          onClick={() => submitTds(false)}
          key="submit"
        >
          <Tooltip
            title={!canEdit ? `Can't edit TDSs from external sources` : null}
          >
            {activeTds ? 'Update TDS' : 'Add Material'}
          </Tooltip>
        </Button>,
        ...(ready && canDuplicate
          ? [
              <Button
                key="submitNew"
                type="primary"
                onClick={() => submitTds(true)}
              >
                Create New TDS
              </Button>
            ]
          : [])
      ]}
    >
      <div ref={modalTopRef}>
        {error && <Alert description={error.toString()} type="error" />}
        {tdsFetchError && (
          <Alert description={tdsFetchError.toString()} type="error" />
        )}
      </div>
      <Form
        form={form}
        validateMessages={{
          required: 'This field is required.'
        }}
        requiredMark={false}
        layout="vertical"
        preserve
        onFinish={submitTds}
        disabled={tdsUpdating}
      >
        <div className="technical-data-sheet-form">
          <FormSection
            label="What recycled material are you selling?"
            wide
            extra="Select one"
          >
            <Form.Item name="type" rules={[{ required: true }]}>
              <MetaPropertyButtonGroup
                className="type-input"
                propertyName="type"
                showIcons
                multiple
              />
            </Form.Item>
          </FormSection>
          <FormSection label="Material Source Type">
            <Form.Item name="sourceType" rules={[{ required: false }]}>
              <MetaPropertySelect propertyName="source-type" />
            </Form.Item>
          </FormSection>
          <FormSection
            label="Form"
            extra="What form(s) can you provide this material in?"
          >
            <Form.Item name="form" rules={[{ required: true }]}>
              <MetaPropertyButtonGroup
                className="form-input"
                propertyName="form"
                multiple
              />
            </Form.Item>
          </FormSection>
          <FormSection
            label="Color"
            extra="What colors can you provide this material in?"
          >
            <Form.Item name="color" rules={[{ required: false }]}>
              <MetaPropertyButtonGroup
                className="color-input-field"
                propertyName="color"
                filter={optionFilter}
                multiple
              />
            </Form.Item>
          </FormSection>
          <FormSection label="Data Source" extra="Where did you get this data?">
            <Form.Item rules={[{ required: true }]} name="source">
              <Select
                options={[
                  {
                    value: 'supplier_manual',
                    label: "Found in supplier's website"
                  },
                  {
                    value: 'supplier_outreach',
                    label: 'Supplier shared directly'
                  },
                  {
                    value: 'other',
                    label: 'Other (please specify)'
                  }
                ]}
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.source !== currentValues.source
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('source') === 'other' ? (
                  <Form.Item
                    rules={[{ required: true }]}
                    name={['extra', 'source_details']}
                  >
                    <Input placeholder="Data source" />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </FormSection>
          <Button
            type="link"
            htmlType="button"
            onClick={() => setShowAllProperties(!showAllProperties)}
          >
            {showAllProperties
              ? `- Hide additional properties`
              : `+ Show additional properties`}
          </Button>

          <FormSection
            // We hide using CSS so fields get preserved
            className={showAllProperties ? '' : 'hidden'}
            label="Additional properties"
          >
            {extraMetaProperties.map((p, i) => (
              <Form.Item
                label={p.name}
                key={p.uuid}
                name={['extra_properties', i]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Please select"
                  options={extraMetaPropertyOptions[i]}
                />
              </Form.Item>
            ))}
            <Form.Item
              label="% recycled by volume"
              key="percent_pcr_by_volume"
              name="percent_pcr_by_volume"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item label="SKU" key="sku" name="sku">
              <Input />
            </Form.Item>
          </FormSection>

          <FormSection
            label="Availability & Capacity"
            extra="How much inventory do you have available on hand, and what is your maximum monthly production volume for this material?"
          >
            <Form.Item
              label="Capacity"
              name="capacity_lbs"
              rules={[{ required: false }]}
            >
              <Input suffix="lb" />
            </Form.Item>
          </FormSection>
          <FormSection
            label="Location"
            extra="Where do you produce this material?"
          >
            <Form.Item name="locations" rules={[{ required: false }]}>
              <MultipleLocationInput placeholder="Search..." />
            </Form.Item>
          </FormSection>
          <FormSection className="kpis" label="Key Performance Metrics">
            {numericalProperties
              .sort((a, b) => {
                // Props not populated nor default get pushed to bottom
                if (!npOrder.includes(a.code)) return 1;
                if (!npOrder.includes(b.code)) return -1;

                return npOrder.indexOf(a.code) - npOrder.indexOf(b.code);
              })
              .slice(0, showAllKpis ? undefined : npOrder.length)
              .map((np) => (
                <Form.Item
                  key={np.uuid}
                  name={['material_numerical_properties', np.uuid]}
                >
                  <FormControlEmbeddedLabel label={np.name}>
                    {(props) => (
                      <SwitchableInput
                        key={`si-${np.uuid}`}
                        Component={PropertyRangeInput}
                        componentProps={{ property: np }}
                        size="small"
                        {...props}
                      />
                    )}
                  </FormControlEmbeddedLabel>
                </Form.Item>
              ))}
            <Button
              type="link"
              htmlType="button"
              onClick={() => setShowAllKpis(!showAllKpis)}
            >
              {showAllKpis
                ? `- Show ${
                    numericalProperties.length - npOrder.length
                  } less KPIs`
                : `+ Show ${
                    numericalProperties.length - npOrder.length
                  } more KPIs`}
            </Button>
          </FormSection>
          <FormSection
            label="Additional Comments"
            extra="Any additional information we should know about this material
                (additional performance metrics, cleaning processes etc)?"
          >
            <Form.Item name="description">
              <Input.TextArea rows={6} />
            </Form.Item>
          </FormSection>
          <FormSection
            label="Documents"
            extra="Please upload all relevant test data, certifications, and/or Technical Data Sheets for your materials"
          >
            <Form.Item name="documents">
              <UploadDocuments />
            </Form.Item>
          </FormSection>
        </div>
      </Form>
    </Modal>
  );
}

TechnicalDataSheet.propTypes = {
  tdsId: PropTypes.string,
  supplierId: PropTypes.string,
  open: PropTypes.bool,
  canDuplicate: PropTypes.bool,
  hideModal: PropTypes.func,
  showCompany: PropTypes.bool,
  showAdminLinks: PropTypes.bool,
  onSuccess: PropTypes.func
};

export default TechnicalDataSheet;
