import React from 'react';
import { Button, Flex, Form, Typography } from 'antd';
import PropTypes from 'prop-types';
import { SUPPLIER_CONFIGURATION_STEPS } from 'src/constants';
import UploadDocuments from 'src/components/form/UploadDocuments';
import BuyerTestingSetupLayout from './BuyerTestingSetupLayout';
import styles from './ComplianceStep.module.less';

function ComplianceStep({ onChangeStep }) {
  const [form] = Form.useForm();

  return (
    <BuyerTestingSetupLayout>
      <Flex className="w-full mt-60" align="center" justify="space-between">
        <div>
          <Typography.Title level={3}>
            Compliance Documentation
          </Typography.Title>
          <Typography.Text type="secondary">
            Send documents to suppliers to fill out before terms are accepted
          </Typography.Text>
        </div>
        <Flex className="gap">
          <Button
            onClick={() =>
              onChangeStep(SUPPLIER_CONFIGURATION_STEPS.CONTRACT_TERMS)
            }
            className={styles.buttonSkip}
            type="text"
          >
            Skip
          </Button>
          <Button
            onClick={() => onChangeStep(SUPPLIER_CONFIGURATION_STEPS.TESTING)}
          >
            Back
          </Button>
          <Button
            onClick={() =>
              onChangeStep(SUPPLIER_CONFIGURATION_STEPS.CONTRACT_TERMS)
            }
            type="primary"
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <Form form={form} layout="vertical" className="mt-xxl">
        <Form.Item name="documents" label="Upload Compliance Documents">
          <UploadDocuments maxCount={1} />
        </Form.Item>
      </Form>
    </BuyerTestingSetupLayout>
  );
}

ComplianceStep.propTypes = {
  onChangeStep: PropTypes.func
};

export default ComplianceStep;
