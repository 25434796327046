import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import React, { useState } from 'react';
import { getMarketPriceTickers } from 'src/Query/marketPrice';
import { useDebounce } from 'src/hooks/useDebounce';

function IndexSelect(props) {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  // TODO: handle paging for this component
  const params = {
    search: debouncedSearchValue,
    page_size: 50
  };

  const { data: tickers, isLoading } = useQuery(['tickers', params], () =>
    getMarketPriceTickers(params)
  );

  return (
    <Select
      loading={isLoading}
      options={tickers?.results?.map((ticker) => ({
        value: ticker.uuid,
        label: `(${ticker.source} ${ticker.source_code}) ${ticker.source_name}`,
        tag: `(${ticker.source} ${ticker.source_code}) ${ticker.source_name}`
      }))}
      filterOption={false}
      onSearch={(v) => setSearchValue(v)}
      showSearch
      {...props}
    />
  );
}

export default IndexSelect;
