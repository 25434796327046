import { Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './TermCard.module.less';

function TermCard({ title, children }) {
  return (
    <div className={styles.container}>
      <Typography className={styles.title}>{title}</Typography>
      {children}
    </div>
  );
}

TermCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default TermCard;
