import PropTypes from 'prop-types';
import React from 'react';

export default function Spacer({ size }) {
  return <div style={{ width: size, height: size }} />;
}

Spacer.propTypes = {
  size: PropTypes.number
};
