import React from 'react';
import PropTypes from 'prop-types';
import { CopyOutlined, UserSwitchOutlined } from '@ant-design/icons';
import useCreateMagicLink from 'src/components/sesame/useCreateMagicLink';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { Button, Popover } from 'antd';

export default function ImpersonateButton({ redirect, email }) {
  const { createLink, link, error, isLoading } = useCreateMagicLink(redirect);

  const loadingClass = useLoadingStateClass(isLoading);

  return (
    <Popover
      content={
        link ? (
          <>
            {link}
            <div>
              <Button
                size="small"
                type="default"
                className="mt-sm"
                onClick={() => {
                  global.navigator.clipboard.writeText(link);
                }}
              >
                <CopyOutlined /> Copy link
              </Button>
            </div>
          </>
        ) : null
      }
      title={
        !email
          ? 'No contact email for this supplier'
          : link
          ? 'Magic link - OPEN IN INCOGNITO MODE ONLY'
          : isLoading
          ? 'Loading...'
          : error
          ? 'An error ocurred'
          : 'Click to generate magic link'
      }
    >
      <button
        className={`bare ${loadingClass}`}
        type="button"
        disabled={!email || isLoading}
        onClick={() => createLink(email)}
      >
        <UserSwitchOutlined />
      </button>
    </Popover>
  );
}
ImpersonateButton.propTypes = {
  redirect: PropTypes.string,
  email: PropTypes.string
};
