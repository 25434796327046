import React, { useCallback } from 'react';
import { Alert, Button, Col, message, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { useSuppliersTab } from 'src/components/project/explore/Location';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { addSuppliersToProject, updateProject } from 'src/Mutation';

export default function SupplierList({ locked }) {
  const { activeProjectId, refetchProjects } = useSidebarContext();

  const navigate = useNavigate();

  const { filters, SuppliersTab } = useSuppliersTab({ locked });
  const addSuppliersToCurrenctProject = useCallback(
    (body) => addSuppliersToProject(activeProjectId, body),
    [activeProjectId]
  );

  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const { mutateAsync: mutateConfirm, isMutating: confirmingSuppliers } =
    useMutation({
      mutationFn: addSuppliersToCurrenctProject,
      onSuccess: () => {
        messageApi.info('Confirmed supplier list for this project.', 8);
        queryClient.invalidateQueries(['warehouse', 'technical-data-sheet']);
      },
      onError: (e) => {
        messageApi.error('Error confirming supplier list for this project.', 8);
        // Send error to Sentry
        captureException(e);
      }
    });
  const { mutateAsync: updateProjectMutation, isLoading: updatingProject } =
    useMutation({
      mutationFn: updateProject,
      onSuccess: (response) => {
        refetchProjects();
      },
      onError: (e) => {
        messageApi.error('Error updating project. Please try again.', 8);
        // Send error to Sentry
        captureException(e);
      }
    });

  const confirm = () => {
    Promise.all([
      mutateConfirm({ ...filters, project_id: activeProjectId }),
      updateProjectMutation({
        uuid: activeProjectId,
        stage: 'search'
      })
    ]).then(() => navigate(`../edit-digital-rfq`));
  };

  return (
    <div className="supplier-list">
      {contextHolder}
      <Row className="mt-lg mb" justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4} className="mb-xs">
            Prioritize Supplier List
          </Typography.Title>
          <Typography.Text>
            Remove or edit suppliers and approve list to continue.
          </Typography.Text>
        </Col>
        <Col>
          {!locked && (
            <Button htmlType="button" type="primary" onClick={confirm}>
              Confirm &amp; continue
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mb-lg" justify="space-between" align="middle">
        {locked ? (
          <Col span={24}>
            <Alert
              showIcon
              description="Your Digital RFQ has been sent. This supplier list is locked."
            />
          </Col>
        ) : null}
      </Row>
      {SuppliersTab}
    </div>
  );
}

SupplierList.propTypes = {
  locked: PropTypes.bool
};
