import { Radio } from 'antd';
import React, { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { useFiltersPermissions } from 'src/utils/authentication';

const options = [
  {
    value: 'resin_pellets',
    label: 'Resin / Pellets'
  },
  { value: 'bales', label: 'Bales' },
  { value: 'regrind_flakes', label: 'Flakes' }
];

export default function FormFilter() {
  const [formFilter, setFormFilter] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  const filters = useFiltersPermissions('form_code__in');
  const isDisabled = (attribute) =>
    filters ? !filters.find((o) => o === attribute) : false;
  const filteredOptions = useMemo(
    () => options.map((o) => ({ ...o, disabled: isDisabled(o.value) })),
    [filters]
  );
  return (
    <Radio.Group
      value={formFilter}
      onChange={({ target }) => setFormFilter(target.value)}
      size="small"
      className="form-filter"
    >
      {filteredOptions.map((o) => (
        <Radio.Button disabled={o.disabled} key={o.value} value={o.value}>
          {o.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}
