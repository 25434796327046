import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { createSesameLink } from 'src/Mutation';
import useIsConcierge from 'src/hooks/useIsConcierge';

export default function useCreateMagicLink(url) {
  const userIsConcierge = useIsConcierge();

  const [error, setError] = useState();
  const [link, setLink] = useState();

  const { mutate, isLoading } = useMutation({
    mutationFn: createSesameLink,
    onSuccess: (response) => {
      const redirect = encodeURIComponent(
        url && url.startsWith('/') ? url : `/${url}`
      );

      setLink(
        `${window.origin}/sesame/login${response.query_string}&next=${redirect}`
      );
    },
    onError: (e) => {
      setError('Error creating the invitation link');
    }
  });

  if (!userIsConcierge) {
    return {};
  }

  const createLink = (email) => {
    const trimmedEmail = email ? email.trim() : email;

    return mutate({
      email: trimmedEmail
    });
  };

  return {
    createLink,
    link,
    error,
    isLoading
  };
}
