import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Tag,
  Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getMarketPriceTickers } from 'src/Query/marketPrice';
import AnalyticsTickerLineChart from 'src/components/analytics/AnalyticsTickerLineChart';
import debounce from 'src/utils/debounce';
import { useTimeScaleControl } from '.';

import './MarketsPlayground.less';

/* eslint-disable react/no-unstable-nested-components */
export default function MarketsPlayground() {
  const [lines, setLines] = useState([{}]);

  const { scaleConfig, TimeScaleControl } = useTimeScaleControl();

  return (
    <div id="markets-playground">
      <Typography.Title level={3}>Markets Playground</Typography.Title>
      <Row gutter={[24, 0]}>
        <Col span={9}>
          <Row gutter={[16, 16]}>
            {lines.map((_, i) => (
              <Col key={i} span={24}>
                <SingleLine
                  onChange={(line) =>
                    setLines((oldLines) => {
                      const newLines = [...oldLines];
                      newLines[i] = line;
                      return newLines;
                    })
                  }
                />
              </Col>
            ))}
          </Row>
          <Row style={{ margin: '16px 0 24px' }} gutter={[16, 0]}>
            <Col>
              <Button
                htmlType="button"
                type="primary"
                onClick={() => setLines((oldLines) => [...oldLines, {}])}
              >
                Add line
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="button"
                type="primary"
                danger
                onClick={() => setLines((oldLines) => oldLines.splice(-1))}
              >
                Remove line
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={15}>
          {TimeScaleControl}
          <AnalyticsTickerLineChart
            tickers={lines
              .filter((line) => Object.keys(line)?.length > 0)
              .map((line) => ({ ...line, ...scaleConfig }))}
            colorSeed={0}
            highlightFirstTicker={false}
            old
          />
        </Col>
      </Row>
    </div>
  );
}

const parenthesesRegExp = /\(([^)]+)\)/;

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const tagContent = label.match(parenthesesRegExp)?.[1] || label;
  return (
    <Popover content={label}>
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3
        }}
      >
        {tagContent}
      </Tag>
    </Popover>
  );
};

function SingleLine({ onChange }) {
  const [selectedTickers, setSelectedTickers] = useState();
  const [title, setTitle] = useState();
  const [search, setSearch] = useState();

  const params = {
    search,
    page_size: 50
  };

  const { data: tickers, isLoading } = useQuery(['tickers', params], () =>
    getMarketPriceTickers(params)
  );

  useEffect(() => {
    onChange({ title, uuid__in: selectedTickers });
  }, [selectedTickers, title]);

  return (
    <Card>
      <Form layout="vertical" size="small">
        <Form.Item label="Title">
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="Tickers">
          <Select
            showSearch
            onSearch={debounce(setSearch, 500)}
            // searchValue={search}
            mode="multiple"
            filterOption={false}
            optionLabelProp="tag"
            value={selectedTickers}
            onChange={setSelectedTickers}
            options={tickers?.results?.map((ticker) => ({
              value: ticker.uuid,
              label: `(${ticker.source} ${ticker.source_code}) ${ticker.source_name}`,
              tag: `(${ticker.source} ${ticker.source_code}) ${ticker.source_name}`
            }))}
            loading={isLoading}
            tagRender={tagRender}
          />
        </Form.Item>
      </Form>
    </Card>
  );
}
SingleLine.propTypes = {
  onChange: PropTypes.func
};
