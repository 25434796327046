import { Button, Flex, Typography } from 'antd';
import React from 'react';
import Spacer from 'src/components/common/Spacer';
import PropTypes from 'prop-types';
import { SUPPLIER_CONFIGURATION_STEPS } from 'src/constants';
import BuyerTestingSetupLayout from './BuyerTestingSetupLayout';
import WelcomeCardItem from './WelcomeCardItem';
import TestingIconSvg from './icons/TestingIconSvg';
import ComplianceIconSvg from './icons/ComplianceIconSvg';
import ContractTermIconSvg from './icons/ContractTermIconSvg';

export default function ReviewAndSendStep({ onChangeStep, onSubmit, loading }) {
  const handleSubmit = async () => {
    onSubmit();
  };

  return (
    <BuyerTestingSetupLayout>
      <Flex className="mt-60" align="center" justify="space-between">
        <div>
          <Typography.Title level={3}>
            Review & Send to Suppliers
          </Typography.Title>
          <Typography.Text className="mt-xs" type="secondary">
            Review information before sending to the selected suppliers
          </Typography.Text>
        </div>

        <Flex className="gap">
          <Button
            onClick={() =>
              onChangeStep(SUPPLIER_CONFIGURATION_STEPS.CONTRACT_TERMS)
            }
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
            type="primary"
          >
            Send Requests to 3 Suppliers
          </Button>
        </Flex>
      </Flex>
      <Spacer size={24} />
      <div>
        <WelcomeCardItem>
          <Flex align="center">
            <TestingIconSvg />
            <div>
              <Typography.Title level={4}>Testing</Typography.Title>
              <Typography.Text className="mt-xs" type="secondary">
                Add testing requirements that match your material needs
              </Typography.Text>
            </div>
          </Flex>
        </WelcomeCardItem>
        <WelcomeCardItem>
          <Flex align="center">
            <ComplianceIconSvg />
            <div>
              <Typography.Title level={4}>Compliance</Typography.Title>
              <Typography.Text className="mt-xs" type="secondary">
                Send documents to suppliers to fill out before terms are
                accepted
              </Typography.Text>
            </div>
          </Flex>
        </WelcomeCardItem>
        <WelcomeCardItem>
          <Flex align="center">
            <ContractTermIconSvg />
            <div>
              <Typography.Title level={4}>Contract Terms</Typography.Title>
              <Typography.Text type="secondary">
                Send documents to suppliers to fill out before terms are
                accepted
              </Typography.Text>
            </div>
          </Flex>
        </WelcomeCardItem>
      </div>
    </BuyerTestingSetupLayout>
  );
}

ReviewAndSendStep.propTypes = {
  onChangeStep: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};
