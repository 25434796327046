import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { applyFilterToPropertyOptions } from 'src/utils/properties';

// autocomplete prop indicates that the value returned from this control
// will be the test of the selected option rather than it's uuid
// this is sued for when we use properties to create a list of possible
// values when the the underlying field is a free form text field
function MetaPropertySelect({
  propertyName,
  filter,
  value,
  multiple,
  autocomplete,
  ...props
}) {
  const property = useMetaProperty(propertyName);
  const filteredOptions = useMemo(
    () => applyFilterToPropertyOptions(property?.options, filter, value),
    [property, filter]
  );
  const isMultiple = multiple || Array.isArray(value);

  return (
    <Select
      value={filteredOptions.length > 0 ? value : undefined}
      {...props}
      loading={!property}
      mode={isMultiple && 'multiple'}
      optionFilterProp="label"
    >
      {filteredOptions.map((option) => (
        <Select.Option
          value={autocomplete ? option.value : option.uuid}
          key={option.uuid}
          label={option.value}
        >
          {option.value}
        </Select.Option>
      ))}
    </Select>
  );
}

MetaPropertySelect.propTypes = {
  propertyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  filter: PropTypes.func,
  autocomplete: PropTypes.bool,
  multiple: PropTypes.bool
};

export default MetaPropertySelect;
