import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { updateProjectTds } from 'src/Mutation';

export const stagesMap = {
  queued: {
    label: 'Queued',
    backgroundColor: '#FFE1E1',
    color: '#891501'
  },
  sent: {
    label: 'Sent',
    backgroundColor: '#E1F3FF',
    color: '#015089'
  },
  opened: {
    label: 'Opened',
    backgroundColor: '#E3EFFF',
    color: '#0741AC'
  },
  clicked: {
    label: 'Clicked',
    backgroundColor: '#E5EBFF',
    color: '#0D33CC'
  },
  responded: {
    label: 'Responded',
    backgroundColor: '#F9F0FF',
    color: '#391085'
  },
  quote: {
    label: 'Quote',
    backgroundColor: '#389E0D',
    color: '#F6FFED'
  },
  disqualified: {
    label: 'Disqualified',
    backgroundColor: '#FFF1F0',
    color: '#A8071A'
  }
};

export default function FunnelStageTag({ stage: stageKey, ...props }) {
  const stage = stagesMap[stageKey];

  if (!stage) return null;

  return (
    <div
      {...props}
      style={{
        fontSize: 12,
        fontWeight: 500,
        padding: 4,
        borderRadius: 2,
        display: 'inline-block',
        marginRight: 8,
        fontFamily: 'Roboto',
        lineHeight: '12px',
        color: stage.color,
        backgroundColor: stage.backgroundColor,
        ...props.style
      }}
    >
      {stage.label}
    </div>
  );
}
FunnelStageTag.propTypes = {
  stage: PropTypes.string,
  style: PropTypes.object
};

export function EditableFunnelStageTag({ projectTdsId, stage }) {
  const concierge = useIsConcierge();

  const [currentStage, setCurrentStage] = useState();

  const dropdownMenuItems = useMemo(
    () =>
      Object.keys(stagesMap).map((stageKey, i) => ({
        key: stageKey,
        label: (
          <FunnelStageTag
            style={{
              margin: '6px 4px'
            }}
            stage={stageKey}
          />
        )
      })),
    [stagesMap]
  );

  useEffect(() => {
    setCurrentStage(stage);
  }, [stage]);

  const queryClient = useQueryClient();
  const { mutate: updateProjectTdsMutation, isLoading: updating } = useMutation(
    {
      mutationFn: updateProjectTds,
      onSuccess: () => {
        queryClient.invalidateQueries(['warehouse', 'technical-data-sheet']);
      }
    }
  );

  if (!stage) return null;
  if (!concierge) return <FunnelStageTag stage={stage} />;

  return (
    <Dropdown
      menu={
        concierge
          ? {
              items: dropdownMenuItems,
              onClick: (e) => {
                setCurrentStage(e.key);
                updateProjectTdsMutation({
                  id: projectTdsId,
                  funnel_stage: e.key
                });
              }
            }
          : null
      }
      trigger="click"
    >
      <FunnelStageTag
        style={{
          cursor: 'pointer',
          opacity: updating ? 0.55 : 1
        }}
        stage={currentStage}
      />
    </Dropdown>
  );
}
EditableFunnelStageTag.propTypes = {
  stage: PropTypes.string,
  projectTdsId: PropTypes.number
};
