import dayjs from 'dayjs';

const frequencyToUnits = {
  weekly: 'weeks',
  monthly: 'months',
  quarterly: 'quarters',
  annually: 'years'
};

const frequencyToNormal = {
  weekly: 52,
  monthly: 12,
  quarterly: 4,
  annually: 1
};

export const numPeriods = (start, end, frequency) => {
  if (!end) return 1;
  const from = (start && dayjs(start)) || dayjs();
  const to = dayjs(end);
  const units = frequencyToUnits[frequency];
  if (!units) return 1;
  return to.diff(from, units) || 1;
};

export const normalizePeriod = (frequency) => {
  const n = frequencyToNormal[frequency];
  return n || 1;
};
