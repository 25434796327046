import { Col, Row, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import QuoteComparison from 'src/components/project/procure/QuoteComparison';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import PricingHistory from 'src/components/project/procure/PricingHistory';
import SupplierDetails from 'src/components/project/procure/SupplierDetails';
import TestingPanel from 'src/components/project/procure/TestingPanel';
import CompliancePanel from 'src/components/project/procure/CompliancePanel';
import PropTypes from 'prop-types';
import TermPanel from './TermPanel';

export default function SingleProjectTds({ project }) {
  const { projectTdsId } = useParams();
  const { data: filteredRecords } = useProcureTdss();

  const projectTds = useMemo(
    () =>
      filteredRecords?.results?.find(
        (tds) => tds.project_tds_id === Number(projectTdsId)
      ),
    [filteredRecords, projectTdsId]
  );

  return (
    <div className="search-results">
      <Row className="mb-md" wrap={false} align="middle" gutter={8}>
        <Col flex="1">
          <Typography.Title level={4} className="mt-lg mb-sm">
            <Typography.Text type="secondary">Procure / </Typography.Text>
            {projectTds?.company_name}
          </Typography.Title>
        </Col>
      </Row>
      <Row wrap={false} gutter={[16, 0]}>
        <Col xxl={8} xl={10} lg={12}>
          <ExplorePanel solid className="mb-lg">
            <QuoteComparison />
          </ExplorePanel>
          <ExplorePanel pad className="mb-lg">
            <PricingHistory />
          </ExplorePanel>
          <ExplorePanel pad className="mb-lg">
            <SupplierDetails />
          </ExplorePanel>
        </Col>
        <Col xxl={16} xl={14} lg={12}>
          <TermPanel />
          <TestingPanel />
          <CompliancePanel project={project} projectTds={projectTds} />
        </Col>
      </Row>
    </div>
  );
}

SingleProjectTds.propTypes = {
  project: PropTypes.object
};
