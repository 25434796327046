import { Form, Select, InputNumber } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import { useAvailableUnitsForType, usePreferredUnits } from 'src/utils/units';
import {
  formatterInputCurrency,
  parserInputCurrency
} from 'src/utils/currency';

export default function QuantityWithUnitsInput({
  unitsFieldName,
  per,
  ...props
}) {
  const weightUnits = useAvailableUnitsForType('weight');
  const defaultUnits = usePreferredUnits('weight');

  return (
    <InputNumber
      min={0}
      formatter={formatterInputCurrency}
      parser={parserInputCurrency}
      addonAfter={
        weightUnits && defaultUnits ? (
          <Form.Item
            initialValue={defaultUnits}
            name={unitsFieldName}
            rules={[{ required: true }]}
            noStyle
          >
            <Select
              style={{ width: 65 }}
              options={weightUnits?.map((unit) => ({
                label: `${per ? '/' : ''}${unit}`,
                value: unit
              }))}
            />
          </Form.Item>
        ) : (
          ''
        )
      }
      {...props}
    />
  );
}
QuantityWithUnitsInput.propTypes = {
  unitsFieldName: PropTypes.string,
  per: PropTypes.bool
};
