import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Radio,
  Row,
  Typography
} from 'antd';
import { SUPPLIER_CONFIGURATION_STEPS } from 'src/constants';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spacer from 'src/components/common/Spacer';
import FrequencySelect from 'src/components/form/FrequencySelect';
import IndexSelect from 'src/components/form/IndexSelect';
import { POSITIVE_NUMBER_RULE } from 'src/utils/rules';
import CircularInputNumber from 'src/components/form/CircularInputNumber';
import BuyerTestingSetupLayout from './BuyerTestingSetupLayout';
import styles from './ContractTerms.module.less';

export const CONTRACT_LENGTH_OPTIONS = {
  SHIPMENT_END_DATE: 1,
  NUMBER_OF_SHIPMENTS: 2
};

export const QUALITY_ASSURANCE_OPTIONS = {
  MATERIAL_PHOTO: 1,
  TESTING_RESULTS: 2
};

function ContractTerms({ onChangeStep, contractTermData }) {
  const [form] = Form.useForm();
  const currentContractOption = Form.useWatch('contractOption', form);

  const handleClickNext = async () => {
    const values = await form.validateFields();
    onChangeStep(
      SUPPLIER_CONFIGURATION_STEPS.REVIEW_SEND,
      values?.contract_terms ? values : {}
    );
  };

  return (
    <BuyerTestingSetupLayout>
      <Flex className="w-full mt-60" align="center" justify="space-between">
        <div>
          <Typography.Title level={3}>Contract Terms</Typography.Title>
          <Typography.Text type="secondary">
            Send documents to suppliers to fill out before terms are accepted
          </Typography.Text>
        </div>
        <Flex className="gap">
          <Button
            onClick={() =>
              onChangeStep(SUPPLIER_CONFIGURATION_STEPS.COMPLIANCE)
            }
          >
            Back
          </Button>
          <Button onClick={handleClickNext} type="primary">
            Next
          </Button>
        </Flex>
      </Flex>
      <div className="mt-xxl">
        <Typography.Title className={styles.shipmentTitle} level={5}>
          Shipment Details
        </Typography.Title>
        <Form
          initialValues={{
            contractOption: CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE,
            ...contractTermData
          }}
          layout="vertical"
          className="mt-lg"
          form={form}
        >
          <Row gutter={[24, 0]}>
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'total_contract_volume']}
                label="Total Contract Volume"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Total Contract Volume'
                  },
                  POSITIVE_NUMBER_RULE
                ]}
              >
                <CircularInputNumber className="w-full" />
              </Form.Item>
            </Col>
            <Col span={16} />
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'shipment_quantity']}
                label="Shipment Quantity"
                rules={[
                  { required: true, message: 'Please enter Shipment Quantity' },
                  POSITIVE_NUMBER_RULE
                ]}
              >
                <CircularInputNumber className="w-full" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'shipment_start_date']}
                rules={[
                  {
                    required: true,
                    message: 'Please select First Shipment Date'
                  }
                ]}
                label="First Shipment Date"
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'shipment_frequency']}
                rules={[
                  { required: true, message: 'Please select Shipping Cadence' }
                ]}
                label="Shipping Cadence"
              >
                <FrequencySelect />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="contractOption"
            className="mb-xs"
            label="Contract Length"
            rules={[{ required: true }]}
          >
            <Radio.Group size="small">
              <Radio value={CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE}>
                Shipment End Date
              </Radio>
              <Radio value={CONTRACT_LENGTH_OPTIONS.NUMBER_OF_SHIPMENTS}>
                Number of Shipments
              </Radio>
            </Radio.Group>
          </Form.Item>
          {currentContractOption ===
          CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE ? (
            <Form.Item
              rules={[
                { required: true, message: 'Please select Shipment End Date' }
              ]}
              name={['contract_terms', 'shipment_end_date']}
            >
              <DatePicker className={styles.datePicker} />
            </Form.Item>
          ) : (
            <Form.Item
              name={['contract_terms', 'shipment_count']}
              rules={[
                { required: true, message: 'Please enter Number of Shipments' },
                POSITIVE_NUMBER_RULE
              ]}
            >
              <CircularInputNumber className={styles.inputShipment} />
            </Form.Item>
          )}
          <Typography.Title
            className={classNames(styles.shipmentTitle, 'mt-xxl')}
            level={5}
          >
            Quality Assurance Requests
          </Typography.Title>
          <Form.Item
            name="qualityAssurance"
            className="mt-lg"
            label="Quality Assurance"
            rules={[{ required: true }]}
          >
            <Checkbox.Group>
              <Checkbox value={QUALITY_ASSURANCE_OPTIONS.MATERIAL_PHOTO}>
                Require Material Photo
              </Checkbox>
              <Checkbox value={QUALITY_ASSURANCE_OPTIONS.TESTING_RESULTS}>
                Require Testings Results
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name={['contract_terms', 'assurance_schedule_days']}
            label="Assurance Due Ahead of Shipment"
            rules={[
              {
                required: true,
                message: 'Please enter Assurance Due Ahead of Shipment'
              },
              POSITIVE_NUMBER_RULE
            ]}
          >
            <CircularInputNumber addonAfter="Days" />
          </Form.Item>
          {/* <Form.Item className="mb" label="Circular & Supplier Agreement">
            <Typography.Text type="secondary">
              Review the contract that suppliers have with Circular so you know
              what to expect
            </Typography.Text>
          </Form.Item>
          <Button>Download</Button> */}
          <Typography.Title
            className={classNames(styles.shipmentTitle, 'mt-xxl')}
            level={5}
          >
            Pricing Benchmarks
          </Typography.Title>
          <Row className="mt-lg" gutter={[24, 0]}>
            <Col span={16}>
              <Form.Item
                name="price_index_id"
                rules={[{ required: true }]}
                label="Index"
              >
                <IndexSelect />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            rules={[
              { required: true, message: 'Please enter Movement' },
              POSITIVE_NUMBER_RULE
            ]}
            name={['contract_terms', 'variance']}
            label="Movement"
          >
            <CircularInputNumber addonAfter="%" />
          </Form.Item>
        </Form>
      </div>
      <Spacer size={200} />
    </BuyerTestingSetupLayout>
  );
}

ContractTerms.propTypes = {
  onChangeStep: PropTypes.func,
  contractTermData: PropTypes.object
};

export default ContractTerms;
