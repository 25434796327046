/* eslint-disable react/no-danger */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const makeDateTimestamp = (timestamp) => {
  if (!timestamp) return '';

  const sourceDate = new Date(timestamp * 1000);
  return sourceDate.toLocaleString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: undefined // Omit seconds
  });
};

function Message({ id, createdAt, subject, author, body, isMe, isHtml }) {
  const formattedDate = makeDateTimestamp(createdAt);
  return (
    <div key={id} className={`message-wrapper${!isMe ? ' not-me' : ''}`}>
      <div className="message">
        {isHtml ? (
          <>
            <div
              className="underlined"
              dangerouslySetInnerHTML={{ __html: author }}
            />
            <div dangerouslySetInnerHTML={{ __html: body }} />
            <div className="createdAt">{formattedDate}</div>
          </>
        ) : (
          // Render plain text content
          <p>{body}</p>
        )}
      </div>
    </div>
  );
}

Message.propTypes = {
  id: PropTypes.string,
  createdAt: PropTypes.number,
  subject: PropTypes.string,
  author: PropTypes.string,
  body: PropTypes.string,
  isMe: PropTypes.bool,
  isHtml: PropTypes.bool
};

export function ChatBody({ conversation }) {
  const scrollToRef = useRef(null);
  const firstMessage = conversation?.source;
  const parts = conversation?.conversation_parts?.conversation_parts || [];
  const formattedDate = makeDateTimestamp(conversation?.created_at);

  const messageCount = conversation?.conversation_parts?.total_count || 0;

  // Scroll to the last message whenever messages change
  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageCount]);

  return (
    <div className="track">
      {firstMessage && (
        <div className="message-wrapper" key={firstMessage.id}>
          <div className="message">
            <div
              dangerouslySetInnerHTML={{ __html: firstMessage.author.email }}
            />
            <div
              className="underlined"
              dangerouslySetInnerHTML={{
                __html: `Subject: <strong>${firstMessage.subject}</strong>`
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: firstMessage.body }} />
            <div className="createdAt">{formattedDate}</div>
          </div>
        </div>
      )}
      {parts.map((part, idx) => {
        if (part.part_type === 'comment') {
          const isMe = part.author.email === firstMessage.author.email;
          const isHtml = false;

          return (
            <Message
              id={part.id || idx}
              createdAt={part.created_at}
              author={part.author.email}
              body={part.body}
              isMe
              isHtml
            />
          );
        }

        return '';
      })}

      <div key="scroll-el" ref={scrollToRef} />
    </div>
  );
}

ChatBody.propTypes = {
  conversation: PropTypes.object
};
