import PropTypes from 'prop-types';
import React from 'react';

export default function ShowHide({ hide, children }) {
  return <div style={{ display: hide ? 'none' : 'block' }}>{children}</div>;
}
ShowHide.propTypes = {
  hide: PropTypes.bool,
  children: PropTypes.node
};
