import { Select } from 'antd';
import React, { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

import useGetRegions from 'src/Query/useGetRegions';

export default function CountryFilter() {
  const [regionCodeList] = useConciergeContextState([
    'explore',
    'filters',
    'region_code__in'
  ]);
  const {
    data: allRegionsData,
    isLoading: isLoadingRegions,
    isPreviousData: isPreviousDataRegions
  } = useGetRegions();

  const countries = useMemo(() => {
    const isRegionFiltered =
      Array.isArray(regionCodeList) && regionCodeList.length > 0;
    return (allRegionsData || [])
      .filter(
        (region) =>
          !isRegionFiltered ||
          regionCodeList.find((regionCode) => regionCode === region.code)
      )
      .flatMap((r) =>
        r.countries.map((c) => ({ value: c.code, label: c.name }))
      )
      .sort((a, b) => a.label?.localeCompare(b.label));
  }, [allRegionsData, regionCodeList]);

  const [countryFilter, setCountryFilter, clearCountryFilter] =
    useConciergeContextState(['explore', 'filters', 'country_code__in']);

  const onCountryFilterValuesChange = (values) => {
    if (!values || values.length === 0) {
      clearCountryFilter();
      return;
    }
    setCountryFilter(values);
  };

  return (
    <Select
      mode="multiple"
      isLoading={isLoadingRegions}
      allowClear
      style={{ width: '100%' }}
      placeholder="Select countries"
      optionFilterProp="label"
      defaultValue={[]}
      onChange={onCountryFilterValuesChange}
      options={countries}
      value={countryFilter}
    />
  );
}
