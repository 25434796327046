import React from 'react';
import PropTypes from 'prop-types';
// import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

export default function FigmaEmbed({ figmaUrl, title }) {
  return (
    <iframe
      title={title}
      style={{
        width: '100%',
        height: '100vh'
      }}
      src={`https://www.figma.com/embed?${new URLSearchParams({
        embed_host: 'share',
        'hide-ui': 1,
        'show-proto-sidebar': 0,
        'hotspot-hints': 0,
        scaling: 'scale-down-width',
        url: figmaUrl
      }).toString()}`}
    />
  );
}
FigmaEmbed.propTypes = {
  figmaUrl: PropTypes.string.isRequired,
  title: PropTypes.string
};
