import React, { useMemo, useState } from 'react';
import { Button, message, Steps, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Spacer from 'src/components/common/Spacer';
import { DATETIME_FORMAT, SUPPLIER_CONFIGURATION_STEPS } from 'src/constants';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import { useMutation } from '@tanstack/react-query';
import { createContractTerm, createTdsTest } from 'src/Mutation';
import { numPeriods } from 'src/components/utils/shipment';
import styles from './BuyerSetupTesting.module.less';
import TestingStep from './components/buyer-testing-setup/TestingStep';
import Welcome from './components/buyer-testing-setup/Welcome';
import ContractTerms, {
  CONTRACT_LENGTH_OPTIONS,
  QUALITY_ASSURANCE_OPTIONS
} from './components/buyer-testing-setup/ContractTerms';
import BuyerTestingAntdProvider from './components/buyer-testing-setup/BuyerTestingAntdProvider';
import ComplianceStep from './components/buyer-testing-setup/ComplianceStep';
import ReviewAndSendStep from './components/buyer-testing-setup/ReviewAndSendStep';

export default function BuyerSetupTesting({ project }) {
  const { projectTdsId } = useParams();
  const { data: filteredRecords } = useProcureTdss();

  const projectTds = useMemo(
    () =>
      filteredRecords?.results?.find(
        (tds) => tds.project_tds_id === Number(projectTdsId)
      ),
    [filteredRecords, projectTdsId]
  );

  const [currentStep, setCurrentStep] = useState(null);
  const [testingData, setTestingData] = useState({});
  const [contractTermData, setContractTermData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate: createTdsTestData, isLoading } = useMutation({
    mutationFn: (data) => createTdsTest(data),
    onSuccess: (response) => {
      messageApi.success('Tds data saved successfully');
    },
    onError: (e) => {
      messageApi.error('Error saving tds data');
    }
  });

  const { mutate: createContractTermData, isLoading: contractTermLoading } =
    useMutation({
      mutationFn: (data) => createContractTerm(data),
      onSuccess: (response) => {
        messageApi.success('Contract term data saved successfully');
      },
      onError: (e) => {
        messageApi.error('Error saving contract term data');
      }
    });

  const onChangeStep = (step, data = {}) => {
    setCurrentStep(step);
    if (step === SUPPLIER_CONFIGURATION_STEPS.COMPLIANCE) {
      setTestingData((prev) => ({ ...prev, ...data }));
    }

    if (step === SUPPLIER_CONFIGURATION_STEPS.REVIEW_SEND) {
      setContractTermData((prev) => ({ ...prev, ...data }));
    }
  };

  const onSubmit = async () => {
    if (projectTds) {
      const updatedTestingData = {
        tds: projectTds.tds_id,
        ...testingData
      };
      createTdsTestData(updatedTestingData);
    }
    const { contractOption, price_index_id, qualityAssurance, contract_terms } =
      contractTermData;

    const qa_requests = qualityAssurance.map((qa) => {
      if (qa === QUALITY_ASSURANCE_OPTIONS.MATERIAL_PHOTO) {
        return { name: 'photo' };
      }
      return { name: 'testing' };
    });

    const {
      shipment_end_date,
      shipment_start_date,
      shipment_frequency,
      ...rest
    } = contract_terms;
    const formattedContractTermData = {
      project_tds: Number(projectTdsId),
      price_index_id,
      contract_terms: {
        ...rest,
        qa_requests,
        shipment_count:
          contractOption === CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE
            ? numPeriods(
                shipment_start_date,
                shipment_end_date,
                shipment_frequency
              )
            : contract_terms.shipment_count,
        shipment_start_date: shipment_start_date.format(DATETIME_FORMAT.DATE),
        shipment_frequency
      }
    };
    createContractTermData(formattedContractTermData);
  };

  return (
    <BuyerTestingAntdProvider>
      {contextHolder}
      <div className={styles.buyerHeader}>
        <Typography.Title level={4}>
          Configure Supplier Requests
        </Typography.Title>
        <Steps
          progressDot
          items={[
            {
              title: 'Testing'
            },
            {
              title: 'Compliance'
            },
            {
              title: 'Contract Terms'
            },
            {
              title: 'Review & Send'
            }
          ]}
          className={styles.buyerStep}
          current={currentStep}
        />
        <Button size="small">
          <CloseOutlined />
        </Button>
      </div>
      {currentStep == null && (
        <Welcome
          onClickGetStarted={() =>
            onChangeStep(SUPPLIER_CONFIGURATION_STEPS.TESTING)
          }
        />
      )}
      {currentStep === SUPPLIER_CONFIGURATION_STEPS.TESTING && (
        <TestingStep
          testingData={testingData}
          project={project}
          onChangeStep={onChangeStep}
        />
      )}
      {currentStep === SUPPLIER_CONFIGURATION_STEPS.COMPLIANCE && (
        <ComplianceStep onChangeStep={onChangeStep} />
      )}
      {currentStep === SUPPLIER_CONFIGURATION_STEPS.CONTRACT_TERMS && (
        <ContractTerms
          contractTermData={contractTermData}
          onChangeStep={onChangeStep}
        />
      )}
      {currentStep === SUPPLIER_CONFIGURATION_STEPS.REVIEW_SEND && (
        <ReviewAndSendStep
          loading={contractTermLoading || isLoading}
          onSubmit={onSubmit}
          onChangeStep={onChangeStep}
        />
      )}
      <Spacer size={200} />
    </BuyerTestingAntdProvider>
  );
}

BuyerSetupTesting.propTypes = {
  project: PropTypes.object.isRequired
};
