import React from 'react';
import PropTypes from 'prop-types';

export default function ExplorePanel({
  dark,
  solid,
  header,
  className,
  children,
  pad,
  style
}) {
  return (
    <div
      className={`box-panel ${solid ? 'solid' : ''} ${dark ? 'dark' : ''} ${
        className || ''
      }`}
      style={{
        ...style,
        padding: pad
          ? typeof pad === 'number'
            ? `${pad}px`
            : '24px'
          : undefined
      }}
    >
      {header ? (
        <div
          className="box-panel--header"
          style={{
            margin: pad ? (typeof pad === 'number' ? `-${pad}px` : '-24px') : 0,
            marginBottom: pad
              ? typeof pad === 'number'
                ? `${pad}px`
                : '24px'
              : 0
          }}
        >
          {header}
        </div>
      ) : null}
      {children}
    </div>
  );
}

ExplorePanel.propTypes = {
  dark: PropTypes.bool,
  solid: PropTypes.bool,
  className: PropTypes.string,
  pad: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  header: PropTypes.node,
  style: PropTypes.object
};
