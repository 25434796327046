import {
  CaretDownOutlined,
  DownOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import {
  Badge,
  Col,
  Divider,
  Row,
  Skeleton,
  Slider,
  Space,
  Switch,
  Tooltip,
  Typography
} from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  totalCost,
  totalCostTarget
} from 'src/components/project/source/TdsTable';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import { currencySymbol, currencyValuePrice } from 'src/utils/currency';
import rangesOverlap from 'src/components/utils/rangesOverLap';
import { useLatestRelevantPriceForFilters } from 'src/components/analytics/MarketPriceByFormLineChart';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

const getMinQuote = (tdss) =>
  tdss
    ? Math.min(...tdss.map((tds) => totalCost(tds)).filter((cost) => !!cost))
    : null;
const getMaxQuote = (tdss) =>
  tdss
    ? Math.max(...tdss.map((tds) => totalCost(tds)).filter((cost) => !!cost))
    : null;
const getAvgQuote = (tdss) => {
  if (!tdss) return null;

  const costs = tdss.map((tds) => totalCost(tds)).filter((cost) => !!cost);

  if (!costs.length) return null;

  return costs.reduce((sum, cost) => sum + cost, 0) / costs.length;
};

export default function QuoteComparison() {
  const [compareAllQuotes, setCompareAllQuotes] = useState(false);

  const { projectTdsId } = useParams();
  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss(
    compareAllQuotes
      ? {
          status: undefined,
          funnel_stage: 'quote'
        }
      : undefined
  );

  const projectTds = useMemo(
    () =>
      filteredRecords?.results?.find(
        (tds) => tds.project_tds_id === Number(projectTdsId)
      ),
    [filteredRecords, projectTdsId]
  );
  const activeQuote = useMemo(
    () => projectTds && totalCost(projectTds),
    [projectTds]
  );
  const currency = useMemo(() => projectTds?.project_currency, [projectTds]);
  const units = useMemo(() => projectTds?.project_weight_units, [projectTds]);

  const target = useMemo(
    () =>
      filteredRecords?.meta_data?.project &&
      totalCostTarget(filteredRecords?.meta_data?.project),
    [filteredRecords]
  );
  const noPriceString = useMemo(
    () => `${currencySymbol(currency)} -- /${units}`,
    [currency, units]
  );

  const minQuote = useMemo(
    () => getMinQuote(filteredRecords?.results),
    [filteredRecords]
  );
  const maxQuote = useMemo(
    () => getMaxQuote(filteredRecords?.results),
    [filteredRecords]
  );
  const avgQuote = useMemo(
    () => getAvgQuote(filteredRecords?.results),
    [filteredRecords]
  );
  const padding = useMemo(
    () => (maxQuote - minQuote) * 0.02,
    [minQuote, maxQuote]
  );

  const { activeProject } = useSidebarContext();
  const latestIndexPrice = useLatestRelevantPriceForFilters(
    activeProject?.filters
  );

  const [expandCostBreakdown, setExpandCostBreakdown] = useState();

  const loadingStateClass = useLoadingStateClass(isLoadingFilteredRecords);

  if (!projectTds) return <Skeleton />;

  return (
    <div className={`quotes-comparison ${loadingStateClass}`}>
      <Row>
        <Typography.Text className="lg heavy mb-sm">
          Total Cost Quote
        </Typography.Text>
      </Row>
      <Typography.Paragraph className="xxl heavy mb-0 poppins">
        {activeQuote
          ? currencyValuePrice(activeQuote, currency, units)
          : noPriceString}
      </Typography.Paragraph>
      <Divider />
      <Row
        style={{ cursor: 'pointer' }}
        justify="space-between"
        className="mb-sm"
        onClick={() => setExpandCostBreakdown(!expandCostBreakdown)}
      >
        <Typography.Text strong>Total Cost Breakdown</Typography.Text>
        <Typography.Text type="secondary" className="sm">
          <DownOutlined
            style={{
              transform: !expandCostBreakdown ? 'rotate(-90deg)' : undefined
            }}
          />
        </Typography.Text>
      </Row>
      {expandCostBreakdown ? (
        <>
          <Row justify="space-between" className="mb-xs">
            <Typography.Text>Material Price</Typography.Text>
            <Typography.Text>
              {projectTds.price?.price
                ? currencyValuePrice(projectTds.price?.price, currency, units)
                : '--'}
            </Typography.Text>
          </Row>
          <Row justify="space-between" className="mb-xs">
            <Typography.Text>Freight</Typography.Text>
            <Typography.Text>
              {projectTds.project_freight
                ? currencyValuePrice(
                    projectTds.project_freight,
                    currency,
                    units
                  )
                : '--'}
            </Typography.Text>
          </Row>
          <Row justify="space-between" className="mb-xs">
            <Typography.Text>Tariffs</Typography.Text>
            <Typography.Text>
              {projectTds.project_tariff
                ? currencyValuePrice(projectTds.project_tariff, currency, units)
                : '--'}
            </Typography.Text>
          </Row>
          <Row justify="space-between" className="mb-xs">
            <Typography.Text>Fees</Typography.Text>
            <Typography.Text>
              {projectTds.project_fee
                ? currencyValuePrice(projectTds.project_fee, currency, units)
                : '--'}
            </Typography.Text>
          </Row>
        </>
      ) : null}
      <Divider />
      <Row align="middle" className="mb-sm">
        <Col className="mr-auto">
          <Typography.Text strong>
            Quote Comparison (1 of {filteredRecords.results.length})
          </Typography.Text>
        </Col>
        <Col className="mr-xs">
          <Typography.Text>
            Compare All{' '}
            <Tooltip title="Compare this quote against all quotes received in Source.">
              <Typography.Text className="sm" type="secondary">
                <InfoCircleOutlined />
              </Typography.Text>
            </Tooltip>
          </Typography.Text>
        </Col>
        <Col>
          <Switch
            value={compareAllQuotes}
            onChange={(checked) => setCompareAllQuotes(checked)}
          />
        </Col>
      </Row>
      {/* Using Slider allows us to easily place arbitrary points within range */}
      <Slider
        className="mx-0 mb-xs mt-lg"
        disabled
        value={maxQuote}
        min={minQuote - padding}
        max={maxQuote + padding}
        style={{ pointerEvents: 'none' }}
        styles={{
          rail: {
            background:
              'linear-gradient(90deg, #A4B5FB 0%, #2F54EB 50.05%, #0727A6 100%)',
            height: 8,
            borderRadius: 4
          },
          handle: {
            display: 'none'
          },
          track: {
            display: 'none'
          },
          tracks: {
            display: 'none'
          }
        }}
        marks={{
          [activeQuote]: activeQuote ? (
            <CaretDownOutlined className="caret-mark" />
          ) : null,
          [target]:
            // Hide caret if outside range
            target && rangesOverlap([minQuote, maxQuote], [target, target]) ? (
              <CaretDownOutlined className="caret-mark target" />
            ) : null,
          [latestIndexPrice?.price]:
            // Hide caret if outside range
            latestIndexPrice?.price &&
            rangesOverlap(
              [minQuote, maxQuote],
              [latestIndexPrice.price, latestIndexPrice.price]
            ) ? (
              <CaretDownOutlined className="caret-mark index" />
            ) : null,
          [avgQuote]: <div className="avg-mark">Avg.</div>
        }}
      />
      <Row justify="space-between">
        <Typography.Text>
          {currencyValuePrice(minQuote, currency)}
        </Typography.Text>
        <Typography.Text>
          {currencyValuePrice(maxQuote, currency)}
        </Typography.Text>
      </Row>
      <Space dir="horizontal" size={16} className="mt-sm mb-0">
        {activeQuote ? (
          <Badge
            color="black"
            styles={{ indicator: { marginRight: -4 } }}
            text={`Quote (${currencyValuePrice(activeQuote, currency)})`}
          />
        ) : null}
        {target ? (
          <Badge
            color="green"
            styles={{ indicator: { marginRight: -4 } }}
            text={`Target (${currencyValuePrice(target, currency)})`}
          />
        ) : null}
        {latestIndexPrice?.price ? (
          <Badge
            color="#AAB0BB"
            styles={{ indicator: { marginRight: -4 } }}
            text={`Index (${currencyValuePrice(
              latestIndexPrice.price,
              latestIndexPrice.currency
            )})`}
          />
        ) : null}
      </Space>
    </div>
  );
}
