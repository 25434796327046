import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

export default function StatusPill({ done, children, error }) {
  return (
    <div
      className={`status-pill ${done ? 'done' : ''} ${error ? 'error' : ''}`}
    >
      {done ? <CheckCircleFilled className="mr-xxs" /> : null}
      {error ? <CloseCircleFilled className="mr-xxs" /> : null}
      {children}
    </div>
  );
}
StatusPill.propTypes = {
  done: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.bool
};
