import React, { useEffect, useMemo, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Alert,
  Divider,
  Typography,
  Drawer,
  Modal,
  Input,
  ColorPicker,
  message
} from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FilterOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import useGetRfq from 'src/Query/useGetRfq';
import { submitDeal } from 'src/Mutation';
import { findProperties } from 'src/utils/properties';
import { currencyValuePrice } from 'src/utils/currency';
import prettyNumber from 'src/components/utils/prettyNumber';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';

import 'src/pages/concierge/styles/Funnel.less';
import ObjectInput from 'src/components/form/ObjectInput';
import ProjectFunnel from 'src/pages/project/ProjectFunnel';

export default function ProposalBuilderHeader({ selectedRows }) {
  const [params, setParams] = useSearchParams();
  const rfqId = params.get('rfqId');
  const anonymized = params.get('anonymized') === 'true';
  const [messageApi, contextHolder] = message.useMessage();

  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);

  const { data: rfqFilter, isError: rfqError } = useGetRfq();

  // Mutation to create a Deal
  const { mutate, isLoading } = useMutation({
    mutationFn: submitDeal
  });

  // this doesn't work along with currency conversion
  // const rfqPrice = rfqFilter?.units_price_lbs
  //   ? rfqFilter?.price_per_lb
  //   : rfqFilter?.price;

  const rfqPrice = rfqFilter?.price;
  const rfqPriceWeightUnits = rfqFilter?.price_weight_units;
  // rfqFilter?.units_price_lbs
  //   ? 'lb'
  //   : rfqFilter?.price_weight_units;

  const headerCols = useMemo(
    () =>
      rfqFilter
        ? [
            {
              label: 'Material',
              value: findProperties(rfqFilter, 'type')
                ?.map(({ value }) => value)
                .join(', ')
            },
            {
              label: 'Form',
              value: findProperties(rfqFilter, 'form')
                ?.map(({ value }) => value)
                .join(', ')
            },
            {
              label: 'Asking Price',
              value: currencyValuePrice(
                rfqPrice,
                rfqFilter.currency,
                rfqPriceWeightUnits
              )
            },
            {
              label: 'Total Capacity',
              value: `${prettyNumber(rfqFilter.capacity)} ${
                rfqFilter.capacity_units
              }`
            },
            {
              label: 'Recurring',
              value: `${prettyNumber(rfqFilter.capacity / 12)} ${
                rfqFilter.capacity_units
              }/mo`
            },
            {
              label: 'Destination',
              value: rfqFilter.shipping_addresses
                ?.map((address) => locationRender(address))
                .join(' | ')
            }
          ]
        : [],
    [rfqFilter]
  );

  return (
    <>
      {contextHolder}
      <Row align="middle" className="proposal-builder--header" wrap={false}>
        {rfqFilter ? (
          <>
            <Col>
              <button
                className="bare clear-rfq"
                type="button"
                onClick={() => {
                  // Clear rfqId from URL params
                  setParams({ anonymized });
                }}
              >
                <ArrowLeftOutlined />
              </button>
            </Col>
            <Col>
              {rfqFilter.company?.logo ? (
                <img
                  src={rfqFilter.company?.logo?.url}
                  alt={`${rfqFilter.company?.name} logo`}
                  className="buyer-logo"
                />
              ) : (
                <Typography.Text strong>
                  {rfqFilter.company?.name}
                </Typography.Text>
              )}
            </Col>
            <Col>
              <Divider type="vertical" />
            </Col>
            {headerCols.map(({ label, value }) => (
              <Col className="detail-col" key={label}>
                <Typography.Text type="secondary">{label}</Typography.Text>
                <Typography.Text>{value}</Typography.Text>
              </Col>
            ))}
            <Col className="actions">
              <a
                href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/requestforquote/${rfqId}`}
                target="_blank"
              >
                <Button htmlType="link">RFQ Details</Button>
              </a>
              <Button
                onClick={() => setHistoryDrawerOpen(!historyDrawerOpen)}
                htmlType="link"
              >
                History
              </Button>
              <Button
                onClick={() =>
                  selectedRows.forEach((supplier) => {
                    const projectTds = supplier.tdss.find(
                      ({ uuid: supplierTdsUuid }) =>
                        rfqFilter?.project_tdss?.some(
                          (projectTdsUuid) => supplierTdsUuid === projectTdsUuid
                        )
                    );
                    if (!projectTds)
                      return messageApi.open({
                        type: 'error',
                        content: (
                          <>
                            Supplier <strong>{supplier.name}</strong> has no
                            Project TDS for this RFQ.
                          </>
                        ),
                        duration: 10
                      });

                    return mutate(
                      {
                        rfq_id: rfqId,
                        status: 'draft',
                        supplies: [
                          {
                            technical_data_sheet_id: projectTds.uuid,
                            purpose: 'primary',
                            price_per_lb: 0
                          }
                        ]
                      },
                      {
                        // By passing onSuccess here instead of in the hook, it'll only fire once after
                        // all deals have been created:
                        // https://tanstack.com/query/v4/docs/react/guides/mutations#consecutive-mutations
                        onSuccess: () => {
                          window.open(
                            `/buyer-deal/quote/${rfqId}/proposals`,
                            '_blank'
                          );
                        }
                      }
                    );
                  })
                }
                htmlType="button"
                type="primary"
                loading={isLoading}
                disabled={!selectedRows?.length}
              >
                Build Proposal
              </Button>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <Form
              layout="inline"
              onFinish={(values) => {
                const prms = {
                  ...values,
                  rfqId: values.rfq?.value,
                  rfq: undefined
                };
                setParams(
                  Object.keys(prms)
                    .filter((key) => prms[key] !== undefined)
                    .reduce((obj, key) => {
                      // eslint-disable-next-line no-param-reassign
                      obj[key] = prms[key];
                      return obj;
                    }, {})
                );
              }}
              initialValues={{
                rfqId: rfqFilter?.uuid,
                anonymized
              }}
            >
              <Form.Item name="rfq" label="RFQ">
                <ObjectInput appName="api" modelName="request-for-quote" />
              </Form.Item>
              <Form.Item
                name="anonymized"
                label="Anonymized"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Button
                style={{ marginBottom: '24px' }}
                htmlType="submit"
                type="primary"
                icon={<FilterOutlined />}
              >
                Filter Matching TDSs
              </Button>
            </Form>
            {rfqError && (
              <Alert type="error" description="Error fetching RFQ" showIcon />
            )}
          </Col>
        )}
        <HistoryDrawer
          open={historyDrawerOpen}
          onClose={() => setHistoryDrawerOpen(false)}
          rfq={rfqFilter}
        />
      </Row>
    </>
  );
}
ProposalBuilderHeader.propTypes = {
  selectedRows: PropTypes.array
};

function HistoryDrawer({ open, onClose, rfq }) {
  const queryClient = useQueryClient();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={660}
      rootStyle={{ top: 47 }}
      closeIcon={<ArrowRightOutlined />}
      title={<>Proposal History</>}
    >
      <ProjectFunnel rfq={rfq} />
      <div className="funnel--history">
        {/* <img
              alt="RFQ funnel history screenshot"
              src={dummyFunnelHistory}
              width="100%"
            /> */}
      </div>
    </Drawer>
  );
}
HistoryDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  rfq: PropTypes.object
};

function EditFunnelModal({ open, onClose, setFunnel, funnel }) {
  const defaultFunnel = [
    {
      label: 'Matched',
      count: '192',
      color: '#722ED1',
      details:
        'Prospect suppliers from database who match material and location.'
    },

    {
      label: 'Qualified',
      count: '76',
      color: '#2F54EB',
      details: 'Prospect suppliers who can match material and location.'
    },

    {
      label: 'Screened',
      count: '35',
      color: '#0086E7',
      details:
        'Prospect suppliers pre-qualified as match on material/location and grade, ready for outreach interviews to confirm fit. '
    },

    {
      label: 'Engaged',
      count: '14',
      color: '#13C2C2',
      details:
        'Supplier responded to our request for more information by either providing  an updated TDS or filling out a form.'
    },

    {
      label: 'Approved',
      count: '6',
      color: '#52C41A',
      details:
        "Supplier's updated TDS records and info captured match buyer RFQ specs and other requirements. Prioritized for inclusion in proposal."
    }
  ];

  const [form] = Form.useForm();
  const [stagesCount, setStagesCount] = useState(funnel?.length || 0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStagesCount(funnel?.length || 0);
  }, [funnel]);

  return (
    <Modal
      title="Edit Funnel Stages"
      open={open}
      onCancel={onClose}
      destroyOnClose
      confirmLoading={loading}
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        onFinish={async (values) => {
          setLoading(true);

          const newFunnel = [];

          let i = 0;
          while (values[i]) {
            newFunnel.push(values[i]);
            i += 1;
          }

          await setFunnel(newFunnel);

          setLoading(false);
          onClose();
        }}
        form={form}
        className="funnel--form"
        layout="vertical"
      >
        {[...Array(stagesCount)].map((_, i) => {
          const stage = funnel[i] ||
            defaultFunnel[i] || {
              label: '',
              count: '',
              color: '',
              details: ''
            };
          return (
            // eslint-disable-next-line
            <div key={i} className="funnel--form--stage">
              <Typography.Paragraph strong>Stage #{i + 1}</Typography.Paragraph>
              {Object.keys(stage).map((key) => {
                if (key === 'color')
                  return (
                    <Form.Item
                      initialValue={stage[key]}
                      key={key}
                      label={key}
                      name={[i, key]}
                      getValueFromEvent={(_colorObj, hex) => hex}
                    >
                      <ColorPicker
                        presets={[
                          {
                            label: 'Recommended',
                            colors: [
                              '#722ED1',
                              '#2F54EB',
                              '#0086E7',
                              '#13C2C2',
                              '#52C41A'
                            ]
                          }
                        ]}
                      />
                    </Form.Item>
                  );

                return (
                  <Form.Item
                    initialValue={stage[key]}
                    key={key}
                    label={key}
                    name={[i, key]}
                  >
                    {key === 'details' ? <Input.TextArea /> : <Input />}
                  </Form.Item>
                );
              })}
              <Divider />
            </div>
          );
        })}
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <Button
              block
              danger
              onClick={() =>
                setStagesCount(stagesCount >= 1 ? stagesCount - 1 : 0)
              }
            >
              <MinusCircleOutlined /> Remove Last Stage
            </Button>
          </Col>
          <Col span={12}>
            <Button
              block
              type="primary"
              onClick={() => setStagesCount(stagesCount + 1)}
            >
              <PlusCircleOutlined /> Add Stage
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
EditFunnelModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setFunnel: PropTypes.func,
  funnel: PropTypes.array
};
