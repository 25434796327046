import { useQuery } from '@tanstack/react-query';
import { getProjectTdssContractTerm } from '.';

export default function useGetProjectContractTerm(id) {
  return useQuery(
    ['projectContractTerm', id],
    () => getProjectTdssContractTerm({ project_tdss: id }),
    {
      enabled: !!id
    }
  );
}
