import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  PaperClipOutlined
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Flex, Popover, Row, Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FileList from 'src/components/form/FileList';
import { ReactComponent as EmptyState } from 'src/components/icons/svg/EmptyState.svg';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { Box } from 'src/components/project/explore/summary/helpers';
import StatusPill from 'src/components/project/procure/StatusPill';
import { getTDSTests } from 'src/Query';
import parseApiError, { ApiErrorAlert } from 'src/utils/parseApiError';

export default function TestingPanel() {
  return (
    <ExplorePanel
      header={
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Text className="lg heavy">Testing</Typography.Text>
          </Col>
          <Col>
            <StatusPill>Pending</StatusPill>
          </Col>
        </Row>
      }
      pad
      className="mb-lg procure--testing"
    >
      <IndividualTestCards />
    </ExplorePanel>
  );
}

const stautsToBoxTypeMap = {
  passed: 'success',
  failed: 'danger',
  pending: 'neutral'
};
const stautsToCopyMap = {
  passed: (
    <>
      <CheckCircleFilled style={{ color: '#389E0D' }} /> Pass
    </>
  ),
  failed: (
    <>
      <ExclamationCircleFilled style={{ color: '#FAAD14' }} /> Fail
    </>
  ),
  pending: 'Pending'
};

function IndividualTestCards() {
  const { projectTdsId, projectId } = useParams();
  const navigate = useNavigate();

  const {
    data: testSets,
    isLoading,
    error
  } = useQuery(['tds-tests', projectTdsId], () =>
    getTDSTests({ project_tdss: projectTdsId, all: true })
  );

  const handleClickAddTest = () => {
    navigate(`/project/procure/${projectId}/${projectTdsId}/setup-testing`);
  };

  if (isLoading) return <Skeleton />;

  if (error) return <ApiErrorAlert errors={parseApiError(error)} />;

  if (!testSets?.length) return <EmptyTesting onClick={handleClickAddTest} />;

  return testSets.map(
    (
      {
        uuid,
        date_performed,
        documents,
        comments,
        numerical_property_tests,
        property_tests
      },
      i
    ) => (
      <ExplorePanel
        key={uuid}
        pad={16}
        className={i < testSets.length - 1 ? 'mb-lg' : ''}
      >
        <Row className="mb-lg" align="middle">
          <Col className="mr-auto">
            <Typography.Paragraph className="heavy mt-0 mb-xs">
              Test Results
            </Typography.Paragraph>
            {date_performed ? (
              <Typography.Paragraph className="sm light my-0">
                Test performed {dayjs(date_performed).format('MMM D, YYYY')}
              </Typography.Paragraph>
            ) : null}
          </Col>
          {documents?.length ? (
            <Col>
              <Popover content={<FileList value={documents} />}>
                <Typography.Text className="link-style">
                  <PaperClipOutlined /> {documents.length} attachment(s)
                </Typography.Text>
              </Popover>
            </Col>
          ) : null}
        </Row>
        <div className="procure--testing--boxes mb-md">
          {numerical_property_tests.map((individualTest) => (
            <IndividualTestBox key={individualTest.uuid} {...individualTest} />
          ))}
          {property_tests.map((individualTest) => (
            <IndividualTestBox key={individualTest.uuid} {...individualTest} />
          ))}
        </div>
        <Typography.Paragraph className="sm light my-0">
          Comments
        </Typography.Paragraph>
        <Typography.Paragraph className="my-0">
          {comments || 'None'}
        </Typography.Paragraph>
      </ExplorePanel>
    )
  );
}

function IndividualTestBox({ uuid, status, property: { name } }) {
  return (
    <Box key={uuid} type={stautsToBoxTypeMap[status]}>
      <Row justify="center">
        <Typography.Text className="sm">{name}</Typography.Text>
      </Row>
      <Row justify="center">
        <Typography.Text className="lg heavy">
          {stautsToCopyMap[status]}
        </Typography.Text>
      </Row>
    </Box>
  );
}
IndividualTestBox.propTypes = {
  uuid: PropTypes.string,
  status: PropTypes.string,
  property: PropTypes.shape({ name: PropTypes.string })
};

function EmptyTesting({ onClick }) {
  return (
    <Flex vertical justify="center" align="center" style={{ margin: 40 }}>
      <EmptyState style={{ height: 85 }} />
      <Typography.Text className="heavy mb-sm">No test results</Typography.Text>
      <Button onClick={onClick} size="small" type="primary">
        Add test results
      </Button>
    </Flex>
  );
}

EmptyTesting.propTypes = {
  onClick: PropTypes.func
};
