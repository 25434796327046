import { Button, Flex, Typography } from 'antd';
import React from 'react';
import Spacer from 'src/components/common/Spacer';
import PropTypes from 'prop-types';
import BuyerTestingSetupLayout from './BuyerTestingSetupLayout';
import WelcomeCardItem from './WelcomeCardItem';
import TestingIconSvg from './icons/TestingIconSvg';
import ComplianceIconSvg from './icons/ComplianceIconSvg';
import ContractTermIconSvg from './icons/ContractTermIconSvg';

export default function Welcome({ onClickGetStarted }) {
  return (
    <BuyerTestingSetupLayout>
      <Flex className="mt-60" align="center" justify="space-between">
        <div>
          <Typography.Title level={3}>Welcome to Procure</Typography.Title>
          <Typography.Text className="mt-xs" type="secondary">
            Configure testing, compliance, and contract terms to send to
            suppliers.
          </Typography.Text>
        </div>
        <Button onClick={onClickGetStarted} size="large" type="primary">
          Get Started
        </Button>
      </Flex>
      <Spacer size={24} />
      <div>
        <WelcomeCardItem>
          <Flex align="center">
            <TestingIconSvg />
            <div>
              <Typography.Title level={4}>Testing</Typography.Title>
              <Typography.Text className="mt-xs" type="secondary">
                Add testing requirements that match your material needs
              </Typography.Text>
            </div>
          </Flex>
        </WelcomeCardItem>
        <WelcomeCardItem>
          <Flex align="center">
            <ComplianceIconSvg />
            <div>
              <Typography.Title level={4}>Compliance</Typography.Title>
              <Typography.Text className="mt-xs" type="secondary">
                Send documents to suppliers to fill out before terms are
                accepted
              </Typography.Text>
            </div>
          </Flex>
        </WelcomeCardItem>
        <WelcomeCardItem>
          <Flex align="center">
            <ContractTermIconSvg />
            <div>
              <Typography.Title level={4}>Contract Terms</Typography.Title>
              <Typography.Text type="secondary">
                Send documents to suppliers to fill out before terms are
                accepted
              </Typography.Text>
            </div>
          </Flex>
        </WelcomeCardItem>
      </div>
    </BuyerTestingSetupLayout>
  );
}

Welcome.propTypes = {
  onClickGetStarted: PropTypes.func
};
