import { Button, Radio, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { useFiltersPermissions } from 'src/utils/authentication';

export default function MaterialFilter() {
  const [showMore, setShowMore] = useState();
  const [materialFilter, setMaterialFilter] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);
  const property = useMetaProperty('type');
  const filters = useFiltersPermissions('type_code__in');
  const isDisabled = (attribute) =>
    filters ? !filters.find((o) => o === attribute) : false;
  if (!property) return <Skeleton />;

  return (
    <>
      <Radio.Group
        options={property.options
          .slice(0, showMore ? undefined : 5)
          .map((o) => ({
            label: o.value,
            disabled: isDisabled(o.code),
            value: o.code
          }))}
        value={materialFilter}
        onChange={({ target: { value } }) => setMaterialFilter(value)}
      />
      <Button
        onClick={() => setShowMore(!showMore)}
        type="link"
        htmlType="button"
      >
        {showMore ? '- Show less' : '+ Show more'}
      </Button>
    </>
  );
}
