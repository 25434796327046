import { CheckCircleFilled } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Row, Col, Typography, Button, Space, Skeleton } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { getProject } from 'src/Query';
import { useUser } from 'src/utils/authentication';

export default function DigitalRfqSubmitted() {
  const { data: user } = useUser();
  const { projectId } = useParams();
  const { data: project } = useQuery(
    ['project', projectId],
    () => getProject(projectId),
    {
      enabled: !!projectId
    }
  );

  if (!user) return <Skeleton active />;

  return (
    <Row justify="center" align="middle">
      <Col className="digital-rfq--confirmation">
        <div className="digital-rfq--confirmation--inner">
          <Typography.Title level={1}>
            <CheckCircleFilled style={{ color: '#52C41A' }} />
          </Typography.Title>
          <Typography.Title level={1} className="mt-sm">
            Your quote has been sent
          </Typography.Title>
          <Typography.Paragraph
            style={{
              textAlign: 'left',
              marginTop: '64px',
              maxWidth: '550px'
            }}
          >
            <b>Next steps:</b>
            <ol>
              <li>
                A representative from Circular will contact you within 7 days
                with any questions or to request additional information.
              </li>
              <li>
                You can edit your submission any time before the{' '}
                {project.decision_date
                  ? `${dayjs(project.decision_date).format(
                      'MMMM D, YYYY'
                    )} deadline`
                  : 'buyer makes a decision'}
                .
              </li>
              <li>
                Once the buyer has reviewed all quotes, a representative from
                Circular will follow up with feedback and next steps.
              </li>
            </ol>
          </Typography.Paragraph>
          <div className="digital-rfq--confirmation--connect color-card color-card--info">
            <Typography.Title level={3}>
              Connect With More Buyers on a Global Scale
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              Help us connect you to more buyers from across the globe by
              completing your profile and updating your product information.
            </Typography.Paragraph>
            <Space direction="vertical" size={8}>
              <Link to="/project/supplier">
                <Button size="large" type="primary">
                  Complete Your Supplier Profile
                </Button>
              </Link>
            </Space>
          </div>
        </div>
      </Col>
    </Row>
  );
}
