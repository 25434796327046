import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Row, Col, Form, Alert, Modal, Switch, Input, InputNumber } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { captureException } from '@sentry/react';

import { createOrUpdateTds } from 'src/Mutation';
import { getTDS } from 'src/Query';
import MetaPropertySelect from 'src/components/form/MetaPropertySelect';
import MultipleLocationInput, {
  parseInternalLocation
} from 'src/components/form/MultipleLocationInputSelect';
import UploadDocuments from 'src/components/form/UploadDocuments';
import useMetaProperty from 'src/hooks/useMetaProperty';
import parseApiError from 'src/utils/parseApiError';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';
import MetaPropertySelectFormItem from 'src/components/form/MetaPropertySelectFormItem';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';

const propertyUuids = (existingTds, propertyCode, additionalFilter) =>
  existingTds?.material_properties
    ?.filter((mpo) => {
      if (mpo.meta_property.code !== propertyCode) return false;
      if (additionalFilter) return additionalFilter(mpo);

      return true;
    })
    ?.map(({ uuid }) => uuid);

export default function useManageTds({
  tdsId,
  defaults,
  afterSubmit,
  supplierId,
  additionalTdsParams,
  parseTdsParams
}) {
  const [error, setError] = useState();

  const [form] = Form.useForm();

  const materialType = Form.useWatch(['material_properties_ids', 'type'], form);
  const rpp = useMetaProperty('type', 'rpp');

  const foodGradePropertyOption = useMetaProperty('grade', 'Food Grade');
  const foodGrade = Form.useWatch(['material_properties_ids', 'grade'], form);

  const { data: existingTds, isLoading } = useQuery(
    ['technical-data-sheet', tdsId],
    () => getTDS(tdsId),
    {
      enabled: !!tdsId
    }
  );

  const pcrbv = useMetaNumericalProperty('PCRBV');
  const numericalProperties = useMemo(() => ({ pcrbv }), [pcrbv]);

  // Populate form with loaded TDS or passed defaults
  useEffect(() => {
    if (foodGradePropertyOption) {
      if (existingTds) {
        const material_numerical_properties = {};
        Object.keys(numericalProperties).forEach((k) => {
          const mnp = existingTds.material_numerical_properties.find(
            (o) => o.property.uuid === numericalProperties[k].uuid
          );
          if (mnp) {
            material_numerical_properties[k] = mnp.max || mnp.min;
          }
        });
        const values = {
          ...existingTds,
          material_numerical_properties,
          material_properties_ids: {
            type: propertyUuids(existingTds, 'type')[0],
            form: propertyUuids(existingTds, 'form')[0],
            'polymer-type': propertyUuids(existingTds, 'polymer-type'),
            'processing-method': propertyUuids(
              existingTds,
              'processing-method'
            ),
            application: propertyUuids(existingTds, 'application'),
            color: propertyUuids(existingTds, 'color'),
            'source-type': propertyUuids(existingTds, 'source-type'),
            certs: propertyUuids(
              existingTds,
              'certs',
              (o) => !o.extra?.is_food_grade
            ),
            food_certs: propertyUuids(
              existingTds,
              'certs',
              (o) => !!o.extra?.is_food_grade
            ),
            grade: propertyUuids(existingTds, 'grade')?.includes(
              foodGradePropertyOption.uuid
            )
              ? foodGradePropertyOption.uuid
              : undefined
          }
        };
        form.resetFields();
        form.setFieldsValue(values);
      } else if (defaults) {
        form.setFieldsValue(defaults);
      }
    }
  }, [existingTds, foodGradePropertyOption, defaults, numericalProperties]);

  const queryClient = useQueryClient();

  const { mutateAsync: mutateTds, isLoading: isMutatingTds } = useMutation({
    mutationFn: createOrUpdateTds,
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['technical-data-sheet']
      });
      queryClient.invalidateQueries({
        queryKey: ['warehouse', 'technical-data-sheet']
      });
    },
    onError: (e) => {
      const errorObj = e.detail;

      const fieldErrors = parseApiError(errorObj, form);

      setError(fieldErrors);

      window.console.error('Error updating TDS', e, errorObj);
      captureException(e);
    }
  });

  const isMutating = isMutatingTds;

  const onFinish = useMemo(
    () => async (values) => {
      setError(null);

      await form.validateFields();

      const material_properties_ids = [];

      let companyId;

      if (existingTds) {
        existingTds.material_properties
          .filter(({ meta_property: { code }, uuid }) => {
            // We can overwrite the properties that are in this form; but don't want to wipe other ones
            if (
              [
                'type',
                'form',
                'processing-method',
                'polymer-type',
                'application',
                'certs'
              ].includes(code)
            )
              return false;

            // For grade we can only set / delete the food grade option
            if (uuid === foodGradePropertyOption.uuid) return false;

            return true;
          })
          .forEach(({ uuid: mpoUuid }) =>
            material_properties_ids.push(mpoUuid)
          );
      } else {
        companyId = supplierId;
      }

      const material_numerical_properties = [];
      Object.keys(numericalProperties).forEach((k) => {
        const value = values.material_numerical_properties[k];
        if (Number.isFinite(value)) {
          material_numerical_properties.push({
            property_id: numericalProperties[k].uuid,
            min: values.material_numerical_properties[k],
            max: values.material_numerical_properties[k]
          });
        }
      });

      const tdsParams = {
        ...values,
        uuid: existingTds?.uuid,
        is_capability: false,
        status: 'document_uploaded',
        source: 'supplier_direct',
        documents_ids: values.documents?.map(({ uuid: docUuid }) => docUuid),
        locations: values.locations.map((loc) => parseInternalLocation(loc)),
        material_properties_ids: [
          // Existing properties
          ...material_properties_ids,
          // New properties from form
          ...(values.material_properties_ids
            ? Object.values(values.material_properties_ids)
                .flat()
                .filter((uuid) => !!uuid)
            : []),
          // Food grade y/n
          ...(values.food_grade ? [foodGradePropertyOption?.uuid] : [])
        ],
        material_numerical_properties,
        company_id: companyId,
        ...(additionalTdsParams || {})
      };

      // Update / create TDS
      const res = await mutateTds(
        typeof parseTdsParams === 'function'
          ? parseTdsParams(tdsParams)
          : tdsParams
      );

      if (afterSubmit) afterSubmit(res);
      if (!existingTds?.uuid) form.resetFields();
    },
    [existingTds, form, mutateTds, numericalProperties, supplierId]
  );

  const FormComponent = (
    <Form
      form={form}
      className="manage-tds-form"
      onFinish={onFinish}
      layout="vertical"
      validateMessages={{
        required: 'This field is required.'
      }}
      disabled={isMutating || (isLoading && tdsId)}
      scrollToFirstError
      onValuesChange={(values) => {
        Object.keys(values).forEach((field) => {
          const fieldError = form.getFieldError(field);
          if (!fieldError.length) {
            return;
          }
          // Clear error message of field
          form.setFields([
            {
              name: field,
              errors: []
            }
          ]);
        });
      }}
    >
      <Row gutter={[20, 0]}>
        <Col xs={24}>
          <Form.Item
            label="Attach TDS"
            extra="We'll pull KPIs and other material data from the attached TDS."
            name="documents"
          >
            <UploadDocuments maxCount={1} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            name={['material_properties_ids', 'type']}
            label="Type"
          >
            <MetaPropertySelect propertyName="type" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            name={['material_properties_ids', 'form']}
            label="Form"
          >
            <MetaPropertySelect propertyName="form" />
          </Form.Item>
        </Col>
        {materialType === rpp?.uuid && (
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              name={['material_properties_ids', 'polymer-type']}
              label="Polymer Type"
            >
              <MetaPropertySelect propertyName="polymer-type" />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} lg={12}>
          <Form.Item name="sku" label="SKU">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            name={['material_properties_ids', 'processing-method']}
            label="Processing Method"
          >
            <MetaPropertySelect multiple propertyName="processing-method" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            name={['material_properties_ids', 'color']}
            label="Color"
          >
            <MetaPropertySelect multiple propertyName="color" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            name={['material_properties_ids', 'source-type']}
            label="Feedstock Source"
          >
            <MetaPropertySelect multiple propertyName="source-type" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            name={['material_numerical_properties', 'pcrbv']}
            label="Percent recycled content"
          >
            <InputNumber addonAfter={<span>%</span>} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <MetaPropertySelectFormItem
            name={['material_properties_ids', 'application']}
            label="Application(s)"
            multiple
            propertyName="application"
            otherOptionFieldName={['extra', 'other_application']}
            required
          />
        </Col>
        <Col xs={24}>
          <Form.Item
            getValueFromEvent={(checked) => {
              if (checked) return foodGradePropertyOption.uuid;

              return null;
            }}
            name={['material_properties_ids', 'grade']}
            label="Food Grade?"
          >
            <Switch />
          </Form.Item>
        </Col>
        {foodGrade && (
          <Col xs={24}>
            <MetaPropertySelectFormItem
              name={['material_properties_ids', 'food_certs']}
              label="Food Grade Certifications"
              filter={(o) => !!o.extra?.is_food_grade}
              propertyName="certs"
              multiple
              required
              otherOptionFieldName={['extra', 'other_food_grade_certs']}
            />
          </Col>
        )}
        <Col xs={24}>
          <MetaPropertySelectFormItem
            name={['material_properties_ids', 'certs']}
            label="Certifications"
            filter={(o) => !o.extra?.is_food_grade}
            propertyName="certs"
            multiple
            otherOptionFieldName={['extra', 'other_certs']}
          />
        </Col>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            label="Shipment Origin"
            name="locations"
          >
            <MultipleLocationInput />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            label="Capacity"
            name="capacity"
          >
            <QuantityWithUnitsInput unitsFieldName="capacity_units" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const ErrorAlert = error ? (
    <Alert
      description={error.map((err) => (
        <>
          {err}
          <br />
        </>
      ))}
      type="error"
      style={{ marginBottom: 16 }}
    />
  ) : null;

  return {
    form,
    FormComponent,
    error,
    ErrorAlert,
    isMutating
  };
}

export function useManageTdsModal(props) {
  const [open, setOpen] = useState();

  const {
    FormComponent: ManageTdsFormComponent,
    form: manageTdsFormInstance,
    ErrorAlert: ManageTdsErrorAlert,
    isMutating: isMutatingManageTds
  } = useManageTds({
    ...props,
    afterSubmit: () => {
      if (props?.afterSubmit) props.afterSubmit();

      setOpen(false);
    }
  });

  return {
    ManageTdsModal: (
      <Modal
        width={817}
        title={props?.tdsId ? 'Manage TDS' : 'Add TDS'}
        open={open}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          loading: isMutatingManageTds
        }}
        okText="Save"
        onOk={manageTdsFormInstance.submit}
        destroyOnClose
      >
        {ManageTdsFormComponent}
        {ManageTdsErrorAlert}
      </Modal>
    ),
    openFunc: () => setOpen(true),
    closeFunc: () => setOpen(false)
  };
}
