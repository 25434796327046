import { Alert } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export default function parseApiError(errorObj, form) {
  const errorMessages = [];

  if (typeof errorObj === 'object') {
    Object.keys(errorObj).forEach((fieldName) => {
      let fieldErrorStr = errorObj[fieldName];

      if (Array.isArray(fieldErrorStr) && fieldErrorStr.length === 1) {
        [fieldErrorStr] = fieldErrorStr;
      }

      if (typeof fieldErrorStr === 'object') {
        fieldErrorStr = JSON.stringify(fieldErrorStr);
      }

      errorMessages.push(`${fieldName}: ${fieldErrorStr}`);

      if (form?.getFieldValue(fieldName) !== undefined) {
        form.setFields([
          {
            name: fieldName,
            errors: [fieldErrorStr]
          }
        ]);
      }
    });
  }

  return errorMessages;
}

export function ApiErrorAlert({ errors }) {
  return Array.isArray(errors) && errors.length ? (
    <Alert
      description={errors.map((err) => (
        <>
          {err}
          <br />
        </>
      ))}
      type="error"
      style={{ marginBottom: 16 }}
    />
  ) : null;
}
ApiErrorAlert.propTypes = {
  errors: PropTypes.func
};
