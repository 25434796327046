import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import Private from 'src/pages/auth/Private';
import Header from 'src/pages/home/Header';
import Analytics from 'src/pages/analytics';
import ExpertAsAService from 'src/pages/expert';
import EsgCredits from 'src/pages/esgCredits';
import SupplyChainRisk from 'src/pages/supplyChainRisk';
import ConciergePage from 'src/pages/concierge/ConciergePage';
import ProposalBuilder from 'src/pages/concierge/ProposalBuilder';
import VerifiedCertificationPage from 'src/pages/verifiedCertification';
import { isConcierge as isConciergeFunc } from 'src/utils/permissions';
import RequestForQuoteView from 'src/pages/rfq';
import TechnicalDataSheetView from 'src/pages/tds';
import BuyerDeal from 'src/pages/rfq/BuyerDeal';
import MapPage from 'src/pages/maps/MapPage';
import DealRouter from 'src/pages/deal/DealRouter';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useIsDealPitch from 'src/hooks/useIsDealPitch';
import ConciergeContextProvider from 'src/components/concierge/ConciergeContext';
import OnboardingPortal from 'src/pages/onboarding';
import OnboardingPortalStatus from 'src/pages/onboarding/OnboardingStatus';
import TdsCreatePage from 'src/pages/supplier/TdsCreatePage';
import SearchPage from 'src/pages/search/SearchPage';
import SearchProgressPage from 'src/pages/search/progress';
import MarketsPlayground from 'src/pages/insights/market/playground';
import SupplierExperience from 'src/pages/supplier/SupplierExperience';
import CircularIndices from 'src/pages/marketPrice/CircularIndices';
import CohortPrices from 'src/pages/marketPrice/CohortPrices';

import IntroduceContainer from 'src/pages/supplier/IntroducePage';
import DigitalRfqStepContainer from 'src/pages/supplier/components/digital-rfq-submit/DigitalRfqStepContainer';
import AppLayoutSidebar from './AppLayoutSidebar';

function AppLayout() {
  const isConcierge = useIsConcierge();
  const isDealPitch = useIsDealPitch();

  useEffect(() => {
    if (isConcierge) document.body.classList.add('is-concierge');
    else document.body.classList.remove('is-concierge');

    if (isDealPitch) document.body.classList.add('is-deal-pitch');
    else document.body.classList.remove('is-deal-pitch');

    return () =>
      document.body.classList.remove('is-concierge', 'is-deal-pitch');
  }, [isConcierge, isDealPitch]);

  return (
    <Private>
      <ConciergeContextProvider>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Roboto'
            },
            components: {
              Slider: {
                railSize: 2,
                handleSize: 8,
                handleSizeHover: 10,
                handleLineWidthHover: 2
              }
            }
          }}
        >
          <Routes>
            <Route path="/project/*" element={<AppLayoutSidebar />} />
            <Route path="/supplier/*" element={<SupplierExperience />} />
            <Route
              path="/digital-rfq/:projectId/introduce"
              element={<IntroduceContainer />}
            />
            <Route
              path="/digital-rfq/:projectId/submit"
              element={<DigitalRfqStepContainer />}
            />
            <Route
              path="/onboarding/status"
              element={<OnboardingPortalStatus />}
            />
            <Route path="/onboarding" element={<OnboardingPortal />} />
            <Route path="/marketprice/circular" element={<CircularIndices />} />
            <Route path="/marketprice/cohort" element={<CohortPrices />} />
            <Route path="/search/progress" element={<SearchProgressPage />} />
            <Route exact path="/search" element={<SearchPage />} />
            <Route
              path="*"
              element={
                <div className="app-layout">
                  <Header />
                  <div className="content">
                    <Routes>
                      <Route
                        path="/request-for-quote/:id"
                        element={<RequestForQuoteView />}
                      />
                      <Route
                        path="/technical-data-sheet/:id"
                        element={<TechnicalDataSheetView />}
                      />
                      <Route
                        path="/tds-create/:supplierId"
                        element={<TdsCreatePage />}
                      />

                      <Route path="/buyer-deal/*" element={<BuyerDeal />} />
                      <Route path="/deal/*" element={<DealRouter />} />
                      {/* Splash pages */}
                      <Route path="/expert" element={<ExpertAsAService />} />
                      <Route path="/credits" element={<EsgCredits />} />
                      <Route
                        path="/supply-chain"
                        element={<SupplyChainRisk />}
                      />
                      <Route
                        path="/verified-certification/:id"
                        element={<VerifiedCertificationPage />}
                      />
                      <Route path="/concierge/*" element={<ConciergePages />} />
                      <Route path="/map" element={<MapPage />} />
                      <Route path="/analytics" element={<Analytics />} />
                    </Routes>
                  </div>
                </div>
              }
            />
          </Routes>
        </ConfigProvider>
      </ConciergeContextProvider>
    </Private>
  );
}

function ConciergePages() {
  useEffect(() => {
    document.body.classList.add('concierge-page');

    return () => document.body.classList.remove('concierge-page');
  });
  return (
    <Private permissions={[isConciergeFunc]}>
      <Routes>
        <Route path="/" element={<ConciergePage />} />
        <Route path="/proposal-builder" element={<ProposalBuilder />} />
        <Route path="/markets-playground" element={<MarketsPlayground />} />
      </Routes>
    </Private>
  );
}

export default AppLayout;
