import { InfoCircleTwoTone } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Divider, Modal, Row } from 'antd';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import DefaultDealImg from 'src/images/defaultDealImg.jpeg';
import Stars from 'src/components/utils/Stars';
import { currencyValuePrice } from 'src/utils/currency';
import prettyNumber from 'src/components/utils/prettyNumber';
import MarketPriceDelta from 'src/components/utils/MarketPriceDelta';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import reduceCost from 'src/images/reduceCost.png';
import reduceRisk from 'src/images/reduceRisk.png';
import SingleSupply from './supply/SingleSupply';
import useDealData from './useDealData';
import SupplierAvatar from './supply/SupplierAvatar';
import SupplierName from './supply/SupplierName';

export default function SingleProposal({ deal }) {
  const [costModalOpen, setCostModalOpen] = useState(false);
  const [riskModalOpen, setRiskModalOpen] = useState(false);
  const {
    useLbForPrice,
    pricePerLb,
    price,
    priceWeightUnits,
    useIndexPrice,
    priceAboveIndex,
    quantity,
    quantityLb,
    quantityUnits,
    fee,
    shippingCost,
    shippingUnitCost,
    deliveredCost,
    durationMonths,
    monthlyCost,
    deposit,
    totalCost,
    totalMaterialCost
  } = useDealData(deal);

  const primarySupplies = deal.supplies.filter(
    (supply) => supply.purpose === 'primary'
  );
  const backupSupplies = deal.supplies.filter(
    (supply) => supply.purpose === 'backup'
  );

  const indexPriceComponent =
    priceAboveIndex === 1 ? (
      <div className="price-direction-up">Above mkt price</div>
    ) : priceAboveIndex === -1 ? (
      <div className="price-direction-down">Below mkt price</div>
    ) : priceAboveIndex === 0 ? (
      <div className="price-direction-equal">Equal to mkt price</div>
    ) : null;

  return (
    <div>
      <AdminConsoleLink app="api" type="deal" uuid={deal.uuid} />
      <Row className="single-rfq-proposal" wrap={false}>
        <Col className="single-rfq-proposal--details">
          {deal.status !== 'proposal' ? (
            <Row>
              <Col span={24}>
                <Alert
                  type="warning"
                  showIcon
                  description={`This deal has a status of "${deal.status}".`}
                  closable
                />
              </Col>
            </Row>
          ) : null}
          <Row wrap={false} gutter={32}>
            <Col>
              <img
                className="single-rfq-proposal--details--main-image"
                src={deal.image?.document || DefaultDealImg}
                alt={deal.pitch_content}
              />
            </Col>
            <Col className="single-rfq-proposal--content">
              {deal.tag && (
                <div
                  className={`single-rfq-proposal--badge ${
                    deal.category || ''
                  }`}
                >
                  {deal.tag}
                </div>
              )}
              <Row
                wrap={false}
                gutter={16}
                justify="space-between"
                className="single-rfq-proposal--header"
              >
                <Col>
                  <h1>{deal.pitch_content}</h1>
                  <div className="single-rfq-proposal--suppliers">
                    {deal.supplies.map((supply) => (
                      <div
                        key={supply.uuid}
                        className="single-rfq-proposal--supplier"
                      >
                        <SupplierAvatar
                          supplier={supply.technical_data_sheet.company}
                        />
                        <SupplierName
                          supplier={supply.technical_data_sheet.company}
                        />

                        <AdminConsoleLink
                          app="api"
                          type="technicaldatasheet"
                          uuid={supply.technical_data_sheet.uuid}
                        />
                      </div>
                    ))}
                  </div>
                </Col>
                <Col>
                  <div className="single-rfq-proposal--price">
                    {useIndexPrice ? (
                      <div className="single-rfq-proposal--price-index">
                        {indexPriceComponent}
                      </div>
                    ) : (
                      <>
                        <div className="single-rfq-proposal--price-pp">
                          {currencyValuePrice(price, deal.currency)}
                          <span className="units">
                            &nbsp;/{priceWeightUnits}
                          </span>
                        </div>
                        <div className="single-rfq-proposal--price-delta">
                          <MarketPriceDelta
                            price={pricePerLb}
                            priceIndex={deal.rfq.price_index}
                            properties={deal.rfq.material_properties}
                            location={deal.rfq?.shipping_addresses?.[0]}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <h3>Summary</h3>
              <div className="single-rfq-proposal--description">
                <ReactMarkdown className="markdown">
                  {deal.description}
                </ReactMarkdown>
              </div>
              <h3>Supplier Information</h3>
              <Row
                className="single-rfq-proposal--score"
                justify="space-between"
              >
                <Col className="single-rfq-proposal--overall-score">
                  <div className="single-rfq-proposal--overall-score--value">
                    <h4>Overall Deal Supplier Score</h4>
                    <span>{deal.overall_score || '-'}</span>
                  </div>
                  <p>
                    This overall score reflects the average of the component
                    scores to the right
                  </p>
                </Col>
                <Col className="single-rfq-proposal--score-breakdown">
                  {deal.scores?.map((score) => (
                    <div
                      key={score.uuid}
                      className="single-rfq-proposal--single-score"
                    >
                      <div>{score.title}</div>
                      <Stars percent={Number(score.score) / 5} />
                    </div>
                  ))}
                </Col>
              </Row>
              <div className="single-rfq-proposal--supplies">
                <div>
                  {primarySupplies.map((supply) => (
                    <SingleSupply
                      key={supply.uuid}
                      showGeneral={deal.supplies.length > 1}
                      supplyData={supply}
                      tabs={deal.scores.map((s) => ({
                        key: s.uuid,
                        label: s.title,
                        fields: s.tab_fields
                      }))}
                      rfq={deal.rfq}
                      useLbForPrice={useLbForPrice}
                    />
                  ))}
                </div>

                {backupSupplies.length ? <h3>Backup Suppliers</h3> : null}
                {backupSupplies.map((supply) => (
                  <SingleSupply
                    key={supply.uuid}
                    showGeneral={deal.supplies.length > 1}
                    supplyData={supply}
                    tabs={deal.scores.map((s) => ({
                      key: s.uuid,
                      label: s.title,
                      fields: s.tab_fields
                    }))}
                    rfq={deal.rfq}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="single-rfq-proposal--costs">
          <div className="single-rfq-proposal--costs-card">
            <div className="single-rfq-proposal--costs-entry">
              <span>Quantity</span>
              <span>
                {prettyNumber(quantity)} {quantityUnits}
                {durationMonths ? `/mo.` : ''}
              </span>
            </div>

            <div className="single-rfq-proposal--costs-entry">
              <span>Duration</span>
              <span>
                {durationMonths ? `${durationMonths} mo.` : 'Spot Purchase'}
              </span>
            </div>
            <div className="single-rfq-proposal--costs-entry">
              <span>Cost / {priceWeightUnits}</span>
              <span>
                {useIndexPrice
                  ? indexPriceComponent
                  : currencyValuePrice(price, deal.currency)}
              </span>
            </div>
            {shippingUnitCost ? (
              <div className="single-rfq-proposal--costs-entry">
                <span>Shipping / {priceWeightUnits}</span>
                <span>
                  {currencyValuePrice(shippingUnitCost, deal.currency)}
                </span>
              </div>
            ) : null}
            {shippingCost ? (
              <div className="single-rfq-proposal--costs-entry">
                <span>Delivered cost / {priceWeightUnits}</span>
                <span>
                  {useIndexPrice
                    ? '—'
                    : currencyValuePrice(deliveredCost, deal.currency)}
                </span>
              </div>
            ) : null}
            <div className="single-rfq-proposal--costs-entry">
              <span>Monthly Cost</span>
              <span>
                {useIndexPrice
                  ? '—'
                  : currencyValuePrice(monthlyCost, deal.currency)}
              </span>
            </div>
            <div className="single-rfq-proposal--costs-entry">
              <span>Deal fee</span>
              <span>{currencyValuePrice(fee, deal.currency) || '-'}</span>
            </div>
            <div className="single-rfq-proposal--costs-rebate">
              <h4>Upgrades</h4>
              <Checkbox>
                <strong>
                  Reduce variability{' '}
                  <button
                    className="bare"
                    type="button"
                    onClick={() => setRiskModalOpen(true)}
                  >
                    <InfoCircleTwoTone />
                  </button>
                </strong>
                <br />
                <span>Mitigate against the inevitable logistical hurdles.</span>
              </Checkbox>
              <Checkbox>
                <strong>
                  Reduce costs{' '}
                  <button
                    className="bare"
                    type="button"
                    onClick={() => setCostModalOpen(true)}
                  >
                    <InfoCircleTwoTone />
                  </button>
                </strong>
                <br />
                <span>Receive credits equivalent to your impact.</span>
              </Checkbox>
            </div>
            <Divider />
            <div
              className="single-rfq-proposal--costs-entry"
              style={!deposit ? { fontWeight: 600 } : undefined}
            >
              <span>Total Material Cost</span>
              <span>
                {useIndexPrice
                  ? '—'
                  : currencyValuePrice(totalMaterialCost, deal.currency)}
              </span>
            </div>
            <div
              style={!deposit ? { fontWeight: 600 } : undefined}
              className="single-rfq-proposal--costs-entry"
            >
              <span>Total Delivered Cost</span>
              <span>
                {useIndexPrice
                  ? '—'
                  : currencyValuePrice(totalCost, deal.currency)}
              </span>
            </div>
            {deposit ? (
              <div
                className="single-rfq-proposal--costs-entry"
                style={{ fontWeight: 600 }}
              >
                <span>
                  Initial Deposit
                  <span className="single-rfq-proposal--costs-entry--helper-text">
                    Due today to lock in price & start deal
                  </span>
                </span>
                <span>${prettyNumber(deposit)}</span>
              </div>
            ) : null}
            <Button type="primary" block>
              Confirm and Continue
            </Button>
            {false && (
              <div className="footnote">
                <div className="marker">*</div>
                <div>
                  Using current market index price{' '}
                  {currencyValuePrice(price, deal.currency)}
                  &nbsp;/{priceWeightUnits}
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <ReduceCostModal open={costModalOpen} setOpen={setCostModalOpen} />
      <ReduceRiskModal open={riskModalOpen} setOpen={setRiskModalOpen} />
    </div>
  );
}
SingleProposal.propTypes = {
  deal: PropTypes.object
};

function ReduceCostModal({ open, setOpen }) {
  return (
    <Modal
      className="upgrades-modal"
      width={600}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <h2>Cost Savings with Carbon Credits</h2>
      <p>
        Circular helps you measure your CO2e impact and reduce your project
        costs, by valorizing carbon credits associated with your avoided
        emissions from using PCR vs virgin.
      </p>
      <img src={reduceCost} alt="Emission Avoidance Opportunity Graphic" />
    </Modal>
  );
}
ReduceCostModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

function ReduceRiskModal({ open, setOpen }) {
  return (
    <Modal
      className="upgrades-modal"
      width={600}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <h2>Multi-Supplier Sourcing</h2>
      <p>
        Circular provides dual sourcing and push-pull supplier management to
        de-risk supply chain issues such as batch variability and global freight
        delays.
      </p>
      <img src={reduceRisk} alt="Delivery Schedule Graphic" />
    </Modal>
  );
}
ReduceRiskModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};
