import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetch } from 'src/utils/client';
import { getConversationKey } from 'src/Query/messenging';

const sendMessage = async (id, body) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/conversations/${id.toString()}/reply`,
    {
      method: 'POST',
      body: JSON.stringify(body)
    }
  );

export function ChatFooter({ conversationId }) {
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const { mutate: mutateSendMessage, isLoading: isSendingMessage } =
    useMutation({
      mutationFn: (msg) => sendMessage(conversationId, msg),
      onSuccess: (latestConversation) => {
        // Update the cached data directly
        queryClient.setQueryData(
          getConversationKey(conversationId),
          (oldData) => latestConversation
        );
        setMessageBody('');
      },
      onError: (e) => {
        messageApi.error('Error sending message');
      }
    });

  const [messageBody, setMessageBody] = React.useState('');
  const handleChange = (e) => setMessageBody(e.target.value);

  const handleSend = (e) => {
    e.preventDefault();
    if (messageBody) {
      mutateSendMessage({ body: messageBody });
    }
  };

  return (
    <div className="message-capture">
      <textarea
        className="message-input"
        placeholder="Type your message..."
        value={messageBody}
        onChange={handleChange}
      ></textarea>
      <button
        type="button"
        className="send-button"
        onClick={handleSend}
        disabled={isSendingMessage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-send"
        >
          <line x1="22" y1="2" x2="11" y2="13"></line>
          <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
        </svg>
      </button>
    </div>
  );
}

ChatFooter.propTypes = {
  conversationId: PropTypes.number
};
