import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { Empty, Skeleton } from 'antd';
import { useToken, useUser } from 'src/utils/authentication';
import useIsSupplier from 'src/hooks/useIsSupplier';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { DASHBOARD_URL } from './SignIn';

export const PRIVATE_MODES = {
  redirect: <DefaultUserRedirect />,
  hide: null,
  showBanner: (
    <Empty className="mt-2xxl" description="You do not have access." />
  )
};
function Private({
  children,
  permissions = [],
  mode = PRIVATE_MODES.redirect
}) {
  const { data: user, isFetched, isError } = useUser();
  const { access, refresh } = useToken();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const sesame = searchParams.get('sesame');

  if (!isFetched) return <Skeleton />;

  const signInRedirect = !sesame
    ? `/login?next=${window.location.pathname}`
    : `/sesame/login?sesame=${sesame}&next=${window.location.pathname}`;

  // We always want to redirect to login screen if the user is not authenticated
  if ((!access && !refresh && !user && pathname !== '/login') || isError)
    return <Navigate to={signInRedirect} />;

  // If the user is authenticated but unauthorized, we can choose what to do
  if (!permissions.every((permissionFunc) => permissionFunc(user))) {
    // Prevent infinite redirect back to Dashboard
    if (mode !== 'redirect' || pathname !== '/project/dashboard')
      return PRIVATE_MODES[mode];
  }

  // we want to guarantee to the children of <Private> that
  // the user data is avaialble.
  // the downside is we serialize some data loads, but only once when the app starts
  return user && children;
}

Private.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.func),
  mode: PropTypes.string
};

export default Private;

function DefaultUserRedirect() {
  const supplier = useIsSupplier();
  const concierge = useIsConcierge();

  if (supplier && !concierge) return <Navigate to="/project/supplier/" />;

  return <Navigate to={DASHBOARD_URL} />;
}
