import PropTypes from 'prop-types';
import React from 'react';
import styles from './WelcomeCardItem.module.less';

export default function WelcomeCardItem({ children }) {
  return <div className={styles.card}>{children}</div>;
}

WelcomeCardItem.propTypes = {
  children: PropTypes.node
};
