import { useQuery } from '@tanstack/react-query';
import {
  Alert,
  Col,
  Divider,
  Empty,
  Flex,
  Popover,
  Row,
  Select,
  Typography
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useParams,
  Link,
  useSearchParams,
  useLocation,
  useNavigate
} from 'react-router-dom';
import ProductInsights from 'src/components/supplier/ProductInsights';
import emptyExplore from 'src/images/exploreEmpty.png';
import { useUser } from 'src/utils/authentication';
import { useTdsCohorts } from 'src/Query/useGetTds';
import { getTdsObjects } from 'src/Query';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import PeriodSelect from 'src/components/form/PeriodSelect';
import MarketPriceByFormLineChart from 'src/components/analytics/MarketPriceByFormLineChart';
import ExploreSection from 'src/components/project/explore/ExploreSection';
import { GlobalOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ProjectIcon from 'src/components/icons/Project';
import SafetyIcon from 'src/components/icons/Safety';
import useMetaPropertyOptionNameFromCode from 'src/hooks/useMetaPropertyOptionNameFromCode';
import { REGIONS } from 'src/pages/insights/dimensions';
import * as PropTypes from 'prop-types';
import PriceInsight from 'src/components/supplier/PriceInsight';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import FormIcon from 'src/components/icons/FormIcon';
import { findPropertiesValues, isTdsFoodGrade } from 'src/utils/properties';
import { useTdsPriceInsights } from 'src/Query/useTdsPriceInsights';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

const howDoesItWork = (
  <Typography.Text>
    The information you provide in response to this request for quote will be
    shared directly with the buyer. The price quote you provide will be
    considered relevant only for this specific opportunity.
    <br />
    <br />
    In addition, Circular.co will use your product specifications to match you
    to future opportunities with buyers on our platform. The more information
    you provide about the products you sell, the more likely you are to surface
    in search results.
  </Typography.Text>
);

const locationRegionNames = (locations) =>
  [
    ...new Set(
      (locations ?? []).flatMap(
        (l) =>
          l.city ||
          l.country?.name ||
          l.country?.regions?.map((r) => r.name) ||
          'Unknown'
      )
    )
  ].join('/');

const splitTdsByLocation = (tds) => {
  if (!tds || tds.locations.length === 0) return tds;
  return tds.locations.map((l) => ({ ...tds, locations: [l] }));
};

const tdsKey = (tds) => tds && `${tds.uuid}:${tds.locations[0]?.uuid}`;

const reduceTitleArray = (prev, curr) =>
  curr ? `${prev} ${curr.trim()}` : prev;

export default function SupplierInsights() {
  const { supplierId } = useParams();
  const [searchParams] = useSearchParams();
  const [tdsId, setTdsId] = useState();
  const [locationId, setLocationId] = useState();
  const [cohort, setCohort] = useState(null);
  const [months, setMonths] = useState(6);
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const [_, setExploreCtx] = useConciergeContextState(['explore']);

  const { filters = {} } = cohort ?? {};

  const { data: user } = useUser();

  const companyId = supplierId || user?.company?.uuid;

  useEffect(() => {
    const id = searchParams.get('tdsId');
    if (id) {
      setTdsId(id);
    }
    const lid = searchParams.get('locationId');
    if (lid) {
      setLocationId(lid);
    }
  }, [searchParams]);

  // TODO: Improve performance of this query
  const { data: companyTdsData, isLoading: loadingTDSs } = useQuery(
    ['api', 'technical-data-sheet', companyId],
    () =>
      getTdsObjects(
        {
          company_or_distributor__uuid: companyId,
          is_capability: false,
          all: true
        },
        true
      ),
    { enabled: !!companyId }
  );

  const companyTdsList = useMemo(
    () =>
      (companyTdsData || []).flatMap((splittingTds) =>
        splitTdsByLocation(splittingTds)
      ),
    [companyTdsData]
  );

  const tds = useMemo(
    () => (companyTdsList || []).find((o) => o.uuid === tdsId),
    [companyTdsList, tdsId]
  );

  const companyName = tds?.company?.name;
  const youTitle = companyName ? `You (${companyName})` : 'You';

  const { data: tdsCohorts, isLoading: loadingCohort } = useTdsCohorts(
    tds?.uuid,
    locationId
  );

  const { data: priceData, isLoading: isLoadingPriceInsights } =
    useTdsPriceInsights(tds?.uuid, locationId);

  const cohortPriceData =
    priceData?.find((pd) => pd.cohort_id === cohort?.uuid) ?? null;

  const { pushCascade, popCascade } = useSidebarContext();

  useEffect(() => {
    pushCascade(undefined, 'supplier-insights', 0);
    return () => popCascade('supplier-insights');
  }, []);
  useEffect(() => {
    if (!tds) return;

    const kpis =
      tds.material_numerical_properties.reduce((kpisObj, kpi) => {
        const {
          property: { code, unit_type }
        } = kpi;

        return {
          ...kpisObj,
          [code]: {
            max: kpi.max,
            min: kpi.min,
            units: kpi.units,
            unit_type,
            isToggledOff: true // We don't want to filter TDSs based on the selected TDS' KPI ranges
          }
        };
      }, {}) || {};

    setExploreCtx((prevState) => ({
      ...prevState,
      filters: {
        ...(prevState.filters || {}),
        kpis
      },
      insights: {
        company: tds.company
      }
    }));
  }, [tds]);

  useEffect(() => {
    if (!Array.isArray(tdsCohorts)) return;
    if (!tdsCohorts.length) setCohort(null);
    else setCohort(tdsCohorts[0]);
  }, [tdsCohorts]);

  const selectTdsOptions = useMemo(
    () =>
      (companyTdsList || [])
        // .filter((o) => o.status !== 'document_uploaded')
        .map((o) => ({
          value: tdsKey(o),
          label: [
            o.sku,
            findPropertiesValues(o, 'type'),
            locationRegionNames(o.locations),
            isTdsFoodGrade(o) ? 'Food Grade' : null,
            findPropertiesValues(o, 'color')
          ].reduce(reduceTitleArray, '')
        })), // SKU #, material, geo, FG (if it's not FG, no need to say non-FG), Color.
    [companyTdsList]
  );

  if (!companyId && user) return <ErrorSupplierInsights />;
  if (!companyId) return null;

  const priceInsightsSupplierCount =
    cohortPriceData?.cohort_avg_supplier_count ?? 0;

  const tdsSelected = (key) => {
    const [id, location] = key.split(':');
    searchParams.set('tdsId', id);
    searchParams.set('locationId', location);
    navigate(`${pathname}?${searchParams.toString()}`);
  };

  const draftCount = companyTdsList?.filter(
    (t) => t.status === 'document_uploaded'
  ).length;

  // if (isLoading) return <Skeleton />;

  const tdsCount = companyTdsList?.length;
  if (!loadingTDSs && !tdsCount) {
    return (
      <Flex className="supplier-insights" vertical>
        <DraftTdsData draftCount={draftCount} tdsCount={tdsCount} />
        <NoTdsData />
      </Flex>
    );
  }

  return (
    <div className="supplier-page">
      <Flex className="supplier-insights" vertical>
        <DraftTdsData draftCount={draftCount} tdsCount={tdsCount} />
        <InsightsTdsSelection
          companyTdsList={companyTdsList}
          loadingTDSs={loadingTDSs}
          onChange={(o) => tdsSelected(o)}
          options={selectTdsOptions}
          value={tdsId && `${tdsId}:${locationId}`}
        />

        <CohortSummaryRow cohort={cohort} />

        <Divider />

        {companyTdsList && !tds ? (
          <EmptySupplierInsights />
        ) : (
          <>
            {tds && cohort ? (
              <ProductInsights cohort={cohort} />
            ) : tds && !cohort ? (
              <NoCohortData />
            ) : null}

            {tds && cohort && cohortPriceData?.latest_price ? (
              <ExploreSection
                className="pricing-section mb-xl"
                description="Compare your product’s price with comparable materials available in your cohort."
                subTitle={`Showing ${priceInsightsSupplierCount} Suppliers`}
                title="Pricing"
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <PriceInsight
                      tds={tds}
                      cohort={cohort}
                      cohortPriceData={cohortPriceData}
                    />
                  </Col>
                  <Col span={16}>
                    <ExplorePanel className="price-history-chart__panel" pad>
                      <Typography.Title
                        level={4}
                        style={{ margin: '0 0 24px 0' }}
                      >
                        Material Pricing History
                      </Typography.Title>
                      <PeriodSelect
                        value={months}
                        onChange={(v) => setMonths(v)}
                      />

                      <MarketPriceByFormLineChart
                        title="Material Price in Region"
                        months={months}
                        filters={filters}
                        priceSettings={{
                          cohort: {
                            ticker_id: cohort?.ticker?.uuid,
                            solid: true
                          },
                          circularPcrResin: {
                            hide: true
                          },
                          pcrResin: {
                            solid: false
                          },
                          tds: {
                            tds_id: tds?.uuid,
                            solid: true,
                            title: () => youTitle
                          }
                        }}
                      />
                    </ExplorePanel>
                  </Col>
                </Row>
              </ExploreSection>
            ) : (
              <ExploreSection
                className="pricing-section-coming-soon"
                description="Compare your product’s price with comparable materials available in your cohort."
                title="Pricing"
              >
                <ExplorePanel pad={54}>
                  {!cohortPriceData?.latest_price ? (
                    <PriceInsightsNoPrice />
                  ) : cohort ? (
                    <PriceInsightsNotAvailableNotice cohort={cohort} />
                  ) : (
                    <Empty image={emptyExplore} description="">
                      <Typography.Title level={4}>
                        Coming Soon!
                      </Typography.Title>
                      <Typography.Paragraph
                        style={{ maxWidth: 354, margin: '0 auto' }}
                      >
                        View and compare material pricing data against other
                        suppliers with similar material offerings
                      </Typography.Paragraph>
                    </Empty>
                  )}
                </ExplorePanel>
              </ExploreSection>
            )}
          </>
        )}
      </Flex>
    </div>
  );
}

const CohortPriceIndexStaleReasons = {
  STALE_NOT_ENOUGH_SUPPLIERS: 'STALE_NOT_ENOUGH_SUPPLIERS',
  STALE_NOT_ENOUGH_TDSS: 'STALE_NOT_ENOUGH_TDSS',
  STALE_NO_RECENT_PRICING_DATA: 'STALE_NO_RECENT_PRICING_DATA'
};

function PriceInsightsNoPrice() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>No price for this material.</Typography.Title>
      <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
        To compare your price to the market, enter a price for this material on
        your <Link to="/supplier">profile</Link> page.
      </Typography.Paragraph>
    </Empty>
  );
}

function PriceInsightsNotAvailableNotice({ cohort }) {
  if (cohort?.ticker?.is_stale) {
    switch (cohort?.ticker?.stale_reason) {
      case CohortPriceIndexStaleReasons.STALE_NOT_ENOUGH_SUPPLIERS:
        return (
          <Empty image={emptyExplore} description="">
            <Typography.Title level={2}>
              Not enough Suppliers in the cohort
            </Typography.Title>
            <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
              We require 2 or more distinct Suppliers to average the price
              index.
            </Typography.Paragraph>
          </Empty>
        );
      case CohortPriceIndexStaleReasons.STALE_NOT_ENOUGH_TDSS:
        return (
          <Empty image={emptyExplore} description="">
            <Typography.Title level={2}>
              Not enough TDSs in the cohort
            </Typography.Title>
            <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
              We require 3 or more distinct TDSs to average the price index.
            </Typography.Paragraph>
          </Empty>
        );
      case CohortPriceIndexStaleReasons.STALE_NO_RECENT_PRICING_DATA:
      default:
        return (
          <Empty image={emptyExplore} description="">
            <Typography.Title level={2}>
              No Recent Pricing Data
            </Typography.Title>
            <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
              Not enough pricing data to build the cohort average.
            </Typography.Paragraph>
          </Empty>
        );
    }
  }
  return null;
}

PriceInsightsNotAvailableNotice.propTypes = {
  cohort: PropTypes.object
};

function EmptySupplierInsights() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Select a SKU/TDS</Typography.Title>
      <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
        View and compare material pricing data against other suppliers with
        similar material offerings
      </Typography.Paragraph>
    </Empty>
  );
}

function NoTdsData({ draftCount }) {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Market Insights</Typography.Title>
      <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
        Upload materials and specifications in your profile to compare pricing
        data against other suppliers with similar material offerings.
      </Typography.Paragraph>
      {!draftCount && (
        <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
          Upload your material specifications on your{' '}
          <Link to="/supplier"> profile</Link> to see insights.
        </Typography.Paragraph>
      )}
    </Empty>
  );
}

NoTdsData.propTypes = {
  draftCount: PropTypes.number
};

function DraftTdsData({ draftCount, tdsCount }) {
  if (draftCount === 0) return undefined;
  if (!tdsCount)
    return (
      <Alert
        description="We are currently processing your material specifications. We will notify
        you when we are done. You will then get more personalized insights."
        type="info"
      />
    );
  return (
    <Alert
      description="We are currently processing your most recent material specifications. We will notify
        you when we are done. Below are your insights on the material specifications we have already processed."
      type="info"
    />
  );
}

DraftTdsData.propTypes = {
  draftCount: PropTypes.number,
  tdsCount: PropTypes.number
};

function NoCohortData() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>No insights for this TDS</Typography.Title>
      <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
        We don't have insight information for the selected TDS.
      </Typography.Paragraph>
    </Empty>
  );
}

function ErrorSupplierInsights() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Additional setup needed</Typography.Title>
      <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
        Please chat to us to get properly setup to see this page
      </Typography.Paragraph>
    </Empty>
  );
}

function InsightsTdsSelection({
  companyTdsList,
  loadingTDSs,
  onChange,
  options,
  value
}) {
  return (
    <div className="tds-selection">
      <Typography.Title className="mb" level={3}>
        Product Insights
      </Typography.Title>
      <Typography.Paragraph>
        Select a specific SKU/TDS to show how your material compares to a cohort
        of suppliers that produce materials of similar quality
      </Typography.Paragraph>
      <Select
        className="material-select"
        options={options}
        onChange={onChange}
        value={value}
        loading={!companyTdsList || loadingTDSs}
      />
    </div>
  );
}

InsightsTdsSelection.propTypes = {
  companyTdsList: PropTypes.any,
  loadingTDSs: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.any,
  value: PropTypes.string
};

function CohortSummaryRow({ cohort }) {
  const {
    food_grade: isFoodGrade,
    form: cohortForm,
    type: cohortType,
    regions
  } = cohort ?? {};

  const type = useMetaPropertyOptionNameFromCode([cohortType]);
  const form = useMetaPropertyOptionNameFromCode([cohortForm]);
  const locations = REGIONS[regions]?.label ?? regions ?? '';

  return (
    <div className="cohort-description display mt-xl">
      <Flex justify="space-between" align="center">
        <Row
          gutter={[12, 12]}
          className="cohort-description--summary"
          style={{ fontSize: '12px' }}
        >
          <Col className="cohort-description--summary--item cohort-description--summary--item">
            <strong>Cohort:</strong>
          </Col>
          <Col className="cohort-description--summary--item">
            <ProjectIcon /> {type}
          </Col>
          <Col className="cohort-description--summary--item">
            <GlobalOutlined /> {locations}
          </Col>
          <Col className="cohort-description--summary--item">
            <FormIcon /> {form}
          </Col>
          <Col className="cohort-description--summary--item">
            <SafetyIcon style={{ verticalAlign: 'middle' }} />{' '}
            <span>{isFoodGrade ? 'Food Grade' : 'Non-Food Grade'}</span>
          </Col>
        </Row>
        <Typography.Text className="how-does-it-work">
          <Popover content={howDoesItWork} title="How it works">
            <QuestionCircleOutlined className="mr-xs" />
            How does it work?
          </Popover>
        </Typography.Text>
      </Flex>
    </div>
  );
}

CohortSummaryRow.propTypes = {
  cohort: PropTypes.object
};
