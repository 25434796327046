import { DownloadOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as EmptyState } from 'src/components/icons/svg/EmptyState.svg';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import StatusPill from 'src/components/project/procure/StatusPill';
import { useProjectTdssCompliance } from 'src/Query/useGetProjectTdssCompliance';
import { getFileNameFromUrl } from 'src/utils/files';
import styles from './CompliancePanel.module.less';

export default function CompliancePanel({ project, projectTds }) {
  const { data: complianceData, isLoading } = useProjectTdssCompliance(
    project.uuid,
    projectTds?.company_id
  );

  const { compliance } = complianceData || {};

  return (
    <Spin spinning={isLoading}>
      <ExplorePanel
        header={
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text className="lg heavy">Compliance</Typography.Text>
            </Col>
            <Col>
              <StatusPill done={!!compliance}>
                {compliance ? 'Complete' : 'Pending'}
              </StatusPill>
            </Col>
          </Row>
        }
        pad
      >
        {!compliance ? (
          <EmptyCompliance />
        ) : (
          <Flex justify="space-between" align="center">
            <Flex className="gap-xs">
              <FileTextOutlined className={styles.textIcon} />
              <Typography.Text className={styles.textIcon}>
                {getFileNameFromUrl(compliance.document_pack)}
              </Typography.Text>
            </Flex>

            <Button icon={<DownloadOutlined />}>
              <a
                target="_blank"
                download={getFileNameFromUrl(compliance.document_pack)}
                href={compliance.document_pack}
                className={styles.buttonText}
              >
                Download
              </a>
            </Button>
          </Flex>
        )}
      </ExplorePanel>
    </Spin>
  );
}

CompliancePanel.propTypes = {
  project: PropTypes.object,
  projectTds: PropTypes.object
};

function EmptyCompliance() {
  return (
    <Flex vertical justify="center" align="center" style={{ margin: 40 }}>
      <EmptyState style={{ height: 85 }} />
      <Typography.Text className="heavy mb-sm">
        Compliance pending
      </Typography.Text>
      <Button size="small" type="primary">
        Upload compliance files
      </Button>
    </Flex>
  );
}
