import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, Input, Space, Spin, Typography } from 'antd';
import { handleTokenPair, useAuthentication } from 'src/utils/authentication';
import { useQuery } from '@tanstack/react-query';
import { fetch } from 'src/utils/client';
import BackgroundPageLayout from 'src/components/layout/BackgroundPageLayout';

export const DASHBOARD_URL = '/project/dashboard';
// labels above controls
// https://github.com/ant-design/ant-design/issues/14063
function SignIn({ next: nextProp }) {
  const { signIn } = useAuthentication();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const email = searchParams.get('email');
  const next = searchParams.get('next');
  const nextParam = next === '/login' ? DASHBOARD_URL : next;

  const { isFetching: isValidatingAccessCode } = useQuery(
    ['code', code],
    () =>
      fetch(`${process.env.REACT_APP_CIRCLE_API}/auth/token/`, {
        method: 'POST',
        body: JSON.stringify({ token: code, email })
      }),
    {
      enabled: !!code,
      onSuccess: (values) => {
        signIn.mutate(
          { code, email },
          {
            onSuccess: ({ access, refresh, detail } = {}) => {
              if (access && refresh) {
                handleTokenPair({ access, refresh });
                navigate(next || nextProp || DASHBOARD_URL);
              } else {
                setError(detail);
              }
            }
          }
        );
      },
      onError: (err) => {
        let errorMessage =
          err?.message || 'Invalid access code, try again. or contact support';

        if (
          typeof errorMessage === 'object' &&
          errorMessage !== null &&
          !Array.isArray(errorMessage)
        ) {
          errorMessage = 'Invalid access code, try again. or contact support';
        }

        setError(errorMessage);

        searchParams.delete('code');
        searchParams.delete('email');

        setSearchParams(searchParams);
        window.console.error('signin error', err);
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
      }
    }
  );

  const [error, setError] = useState('');
  const navigate = useNavigate();
  const onSignIn = async (values) => {
    signIn.mutate(values, {
      onSuccess: () => {
        if (nextProp || next) {
          navigate(nextProp || nextParam);
        } else {
          navigate(DASHBOARD_URL, { state: { reset: true } });
        }
      },
      onError: (err) => {
        if (err === 'PasswordResetRequiredException')
          navigate('/reset-password');

        let errorMessage =
          err?.message ||
          err?.error ||
          err?.detail ||
          'Invalid email or password';

        if (
          typeof errorMessage === 'object' &&
          errorMessage !== null &&
          !Array.isArray(errorMessage)
        ) {
          errorMessage = 'Invalid email or password';
        }

        setError(errorMessage);
      }
    });
  };

  const onSignInFailed = (errorInfo) => {
    window.console.error('SignIn failed:', errorInfo);
  };

  return (
    <BackgroundPageLayout>
      {isValidatingAccessCode ? (
        <Space>
          <Typography.Text>Validating access code...</Typography.Text>
        </Space>
      ) : (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSignIn}
          onFinishFailed={onSignInFailed}
          autoComplete="off"
          className="signin-form auth-form"
        >
          {error && <div className="error">{error}</div>}
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Typography.Title level={3}>Sign in to Circular</Typography.Title>
            <div>
              <Form.Item
                label="Email"
                name="username"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                validateTrigger="onSubmit"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not a valid email address'
                  },
                  {
                    required: true,
                    message: 'Please input your email address'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.Password />
              </Form.Item>
              <Typography.Text className="sm">
                <Link
                  style={{ textDecoration: 'underline' }}
                  to="/forgot-password"
                >
                  Forgot your password?
                </Link>
              </Typography.Text>
            </div>
            <div>
              <Button
                id="signin"
                type="primary"
                htmlType="submit"
                disabled={signIn.isLoading}
                icon={signIn.isLoading && <Spin size="small" />}
              >
                Sign in
              </Button>

              <Typography.Text className="sm">
                Don’t have an account with us?{' '}
                <Link to="https://circular.co/book-a-demo">Book a demo</Link>
              </Typography.Text>
            </div>
          </Space>
        </Form>
      )}
    </BackgroundPageLayout>
  );
}

SignIn.propTypes = {
  next: PropTypes.string
};

export default SignIn;
