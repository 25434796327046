import { InputNumber } from 'antd';
import React from 'react';
import {
  formatterInputCurrency,
  parserInputCurrency
} from 'src/utils/currency';

function CircularInputNumber(props) {
  return (
    <InputNumber
      min={0}
      formatter={formatterInputCurrency}
      parser={parserInputCurrency}
      {...props}
    />
  );
}

export default CircularInputNumber;
