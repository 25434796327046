import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PeriodSelect from 'src/components/form/PeriodSelect';
import MarketPriceByFormLineChart from 'src/components/analytics/MarketPriceByFormLineChart';
import { Row, Typography } from 'antd';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';

export default function MarketPriceByFormLineChartWithControls({
  filters,
  priceSettings = {}
}) {
  const [months, setMonths] = useState(6);

  return (
    <div className="market-price-chart-with-controls">
      <Row justify="space-between" align="middle" className="mb-sm">
        <Typography.Text className="lg heavy">
          <MetaPropertyName
            property="type"
            optionValue={filters.type_code__in}
          />{' '}
          <MetaPropertyName
            property="form"
            optionValue={filters.form_code__in}
          />{' '}
          Pricing History
        </Typography.Text>
        <PeriodSelect value={months} onChange={(v) => setMonths(v)} />
      </Row>
      <div className="market-price-chart-with-controls__chart">
        <MarketPriceByFormLineChart
          months={months}
          title="Material Price in Region"
          filters={filters}
          priceSettings={priceSettings}
        />
      </div>
    </div>
  );
}
MarketPriceByFormLineChartWithControls.propTypes = {
  filters: PropTypes.object,
  priceSettings: PropTypes.object
};
