import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

function DigitalRfqAntdProvider({ children }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          controlHeight: 40,
          controlInteractiveSize: 16,
          borderRadius: 8
        },
        components: {
          Form: {
            itemMarginBottom: 16
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  );
}

DigitalRfqAntdProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DigitalRfqAntdProvider;
