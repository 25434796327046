const demoSteps = [
  {
    label: 'Prioritize Supplier List',
    key: 'prioritize-suppliers',
    done: true
  },
  {
    label: 'Edit & Send Digital RFQ',
    key: 'buyer-digital-rfq', // 'buyer-rfq-approval',
    done: false,
    steps: [
      {
        label: 'Edit Digital RFQ',
        key: 'edit-digital-rfq',
        done: false
      },
      {
        label: 'Compose Messaging',
        key: 'email-templates',
        done: false
      },
      {
        label: 'Confirm & Send',
        key: 'email-send',
        done: false
      }
    ]
  },
  {
    label: 'Manage Quotes',
    key: 'quotes',
    done: false
  }
];

export function projectSteps(project) {
  const prioritizeSupplierListComplete = [
    'search',
    'procure',
    'manage'
  ].includes(project?.stage);
  const drfqSent = ['procure', 'manage'].includes(project?.stage);

  // Recursive function to process steps and their substeps
  const processSteps = (steps) =>
    steps.map((step) => ({
      ...step,
      done:
        (prioritizeSupplierListComplete &&
          step.key === 'prioritize-suppliers') ||
        (drfqSent && step.key === 'buyer-digital-rfq'),
      // Process substeps if they exist
      ...(step.steps ? { steps: processSteps(step.steps) } : {})
    }));

  const steps = project?.workflow?.source || processSteps(demoSteps);

  return steps;
}

export function getNextStep(project) {
  const steps = projectSteps(project);
  return steps.find(({ done }) => !done) || steps[0];
}
