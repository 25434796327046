import { Alert, Button, Col, Row, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useTdsTable } from 'src/components/project/source/TdsTable';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import BlueCircleCubeIcon from 'src/components/icons/BlueCircleCube';

export default function ProcureOverview() {
  // Open text search shouldn't propagate to Explore, so we store in local state only
  const [search, setSearch] = useState();

  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss();

  const queryClient = useQueryClient();
  const setQueryData = useCallback(
    (fn) => queryClient.setQueryData(queryKey, fn),
    [queryKey]
  );
  const invalidateQueryData = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey
      }),
    [queryKey]
  );

  const { TableComponent } = useTdsTable({
    tdss: filteredRecords?.results || [],
    project: filteredRecords?.meta_data?.project,
    showRfqRow: false,
    isLoading: isLoadingFilteredRecords,
    filterColumns: ['company_name', 'price.price', 'total_cost'],
    setQueryData,
    invalidateQueryData,
    extraColumns: [
      {
        title: 'Testing',
        key: 'testing_status',
        width: 100,
        render: () => (
          <div className="procure-column">
            <Typography.Text type="secondary">--</Typography.Text>
          </div>
        )
      },
      {
        title: 'Compliance',
        key: 'compliance_status',
        width: 100,
        render: () => (
          <div className="procure-column">
            <Typography.Text type="secondary">--</Typography.Text>
          </div>
        )
      },
      {
        title: 'Terms',
        key: 'terms_status',
        width: 100,
        render: () => (
          <div className="procure-column">
            <Typography.Text type="secondary">--</Typography.Text>
          </div>
        )
      }
    ],
    localSort: true
  });

  return (
    <div className="procure-overview">
      <Row className="mb-md" wrap={false} align="middle" gutter={8}>
        <Col flex="1">
          <Typography.Title level={4} className="mt-lg mb-sm">
            <Typography.Text type="secondary">Procure / </Typography.Text>
            Overview
          </Typography.Title>
        </Col>
      </Row>
      <Alert
        type="info"
        className="mb-lg"
        showIcon
        icon={<BlueCircleCubeIcon />}
        message="Configure Supplier Requests"
        description="Configure testing, compliance, and contract terms to send to suppliers."
        action={<Button type="primary">Get Started</Button>}
      />
      {/* <Row className="search-results--controls" justify="space-between">
        <Col>
          <DebouncedTextInput
            size="small"
            placeholder="Search prospects"
            onChange={(v) => setSearch(v)}
            suffix={<SearchOutlined />}
          />
        </Col>
      </Row> */}
      {TableComponent}
    </div>
  );
}
