import { useQuery } from '@tanstack/react-query';
import { getProjectTdssCompliance } from 'src/Query/index';

export function useProjectTdssCompliance(projectId, companyId) {
  return useQuery(
    ['getProjectTdssCompliance', projectId, companyId],
    () => getProjectTdssCompliance(projectId, companyId),
    {
      retry: false,
      enabled: !!projectId && !!companyId
    }
  );
}
