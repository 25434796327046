import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, Space } from 'antd';
import { fetch } from 'src/utils/client';
import BackgroundPageLayout from 'src/components/layout/BackgroundPageLayout';

function ForgotPassword({ next }) {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const onForgotPassword = async ({ username }) => {
    try {
      await fetch(`${process.env.REACT_APP_CIRCLE_API}/auth/email/`, {
        method: 'POST',
        body: JSON.stringify({ email: username })
      });

      if (next) navigate(next);
      else navigate('/reset-password');
    } catch (err) {
      window.console.error(err);
      setError(err?.message || 'Error resetting password');
    }
  };
  const onFail = (errorInfo) => {
    window.console.error('Reset Password failed:', errorInfo);
  };

  return (
    <BackgroundPageLayout>
      {error && <div className="error">{error}</div>}

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onForgotPassword}
        onFinishFailed={onFail}
        autoComplete="off"
        className="forgot-password-form auth-form"
      >
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <Typography.Title level={3}>Reset Password</Typography.Title>

          <Form.Item
            label="Company Email"
            name="username"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Request Password Reset
          </Button>
        </Space>
      </Form>
    </BackgroundPageLayout>
  );
}

ForgotPassword.propTypes = {
  next: PropTypes.string
};

export default ForgotPassword;
