import { Button, Col, Collapse, Row, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import FormFilter from 'src/components/project/explore/filters/FormFilter';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import DisableableContent from 'src/components/utils/DisableableContent';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { CheckCircleFilled, LockFilled } from '@ant-design/icons';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import useTdsFilterOptions, {
  useTdsFilterActiveOptions
} from 'src/components/project/useTdsFilterOptions';
import { useFiltersPermissionsExists } from 'src/utils/authentication';

export default function FiltersSidebar() {
  return (
    <div className="filters-sidebar pt-lg">
      <Filters />
    </div>
  );
}

const DEFAULT_FILTERS = {};

function Filters() {
  const [filters, setFilters] = useConciergeContextState([
    'explore',
    'filters'
  ]);

  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  const balesMode = form === 'bales';

  // Returns an array of kpi codes
  const primaryKpis = usePrimaryKpis();
  const allKpis = useMetaNumericalProperty();
  // allow overriding of step status
  const [stepSkip, setStepSkip] = useState([]);

  // Populate Primary KPIs when material changes
  useEffect(() => {
    if (primaryKpis.length && allKpis?.length) {
      const kpiFilters = { ...(filters?.kpis || {}) };
      primaryKpis.forEach((kpiCode) => {
        if (!kpiFilters[kpiCode]) {
          const kpiObj = allKpis.find(({ code }) => code === kpiCode);
          kpiFilters[kpiCode] = {
            units: kpiObj.units,
            unit_type: kpiObj.unit_type,
            allow_null: false
          };
        }
      });
      setFilters({ ...filters, kpis: kpiFilters });
    }
  }, [primaryKpis, allKpis]);

  const step1Done = useMemo(
    () =>
      balesMode
        ? !!filters.type_code__in &&
          !!filters.form_code__in &&
          !!(filters.region_code__in || filters.country_code__in)
        : !!filters.type_code__in &&
          !!filters.form_code__in &&
          filters.kpis &&
          Object.keys(filters.kpis).length >= primaryKpis.length,
    [filters, primaryKpis]
  );
  const step3Done = useMemo(
    () => Object.keys(filters.properties || {}).length > 0,
    [filters]
  );
  const step2Done = useMemo(
    () =>
      balesMode
        ? step1Done &&
          !!filters.properties &&
          Object.keys(filters.properties).length
        : step3Done ||
          stepSkip[1] ||
          (step1Done &&
            filters.kpis &&
            Object.keys(filters.kpis).length > primaryKpis.length),
    [filters, primaryKpis, stepSkip]
  );

  const stepsStatus = useMemo(
    () => [step1Done, step2Done, step3Done],
    [step1Done, step2Done, step3Done]
  );

  const canSkip = [false, true, false];

  const tdsFilterActiveOptions = useTdsFilterActiveOptions(form);

  const tdsFilterOptions = useTdsFilterOptions();

  const filterActiveOptions = useMemo(() => {
    const results = {};
    Object.keys(tdsFilterActiveOptions).forEach((groupKey) => {
      const optionTestMap = tdsFilterActiveOptions[groupKey];
      const optionList = Object.keys(optionTestMap)
        .filter((key) => !key.startsWith('_'))
        .map((key) => {
          const optionTest = optionTestMap[key];
          const include =
            typeof optionTest === 'function' ? optionTest() : optionTest;
          if (include) {
            const option = tdsFilterOptions[key]();
            option.key = key;
            return option;
          }
          return undefined;
        })
        .filter((o) => o);
      results[groupKey] = optionList;
    });
    return results;
  }, [tdsFilterActiveOptions, tdsFilterOptions]);

  const skipSelected = (i) => {
    const s = [...stepSkip];
    s[i] = true;
    setStepSkip(s);
  };
  const isPermissionApplied = useFiltersPermissionsExists();
  return (
    <>
      {isPermissionApplied && (
        <Row gutter={8} className="permission-warning">
          <Col flex="24px">
            <LockFilled />
          </Col>
          <Col flex="1">
            Some filter options may be unavailable, due to your current license.
          </Col>
        </Row>
      )}
      <Row justify="space-between" align="middle" className="mb">
        <Col>
          <Typography.Title level={4}>Filters</Typography.Title>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setFilters(DEFAULT_FILTERS);
              setStepSkip([]);
            }}
            htmlType="button"
            type="link"
          >
            Reset Filters
          </Button>
        </Col>
      </Row>

      <FormFilter />

      {Object.keys(filterActiveOptions).map((groupName, i) => (
        <ExplorePanel key={groupName} className="mt" pad={12}>
          {/* eslint-disable no-underscore-dangle */}
          <Step
            title={
              tdsFilterActiveOptions[groupName]._spread ? false : groupName
            }
            disabled={i !== 0 && !stepsStatus[i - 1]}
            selectSkip={
              canSkip[i] &&
              !stepsStatus[i] &&
              stepsStatus[i - 1] &&
              (() => skipSelected(i))
            }
          >
            <div className="filters-box-group">
              {tdsFilterActiveOptions[groupName]._spread ? (
                filterActiveOptions[groupName].map(({ key, children }) => (
                  <React.Fragment key={key}>{children}</React.Fragment>
                ))
              ) : (
                <Collapse
                  className="filters-box"
                  defaultActiveKey={['primary_specs']}
                  size="small"
                  items={filterActiveOptions[groupName]}
                />
              )}
            </div>
          </Step>
        </ExplorePanel>
      ))}
    </>
  );
}

function StepStatus({ done }) {
  if (!done)
    return (
      <div
        style={{
          width: 16,
          height: 16,
          border: '1px solid #DFE3ED',
          borderRadius: 8,
          display: 'inline-block',
          marginRight: 8
        }}
      />
    );

  return (
    <CheckCircleFilled
      style={{ fontSize: 20, color: '#389E0D', marginRight: 8 }}
    />
  );
}
StepStatus.propTypes = {
  done: PropTypes.bool
};

function StepsProgress({ stepsStatus, right }) {
  return (
    <div
      className="p-sm mb-md box-panel solid"
      style={{
        background: '#F9F0FF'
      }}
    >
      <Row className="mb-sm" justify="space-between">
        <Col>
          <h3>
            {stepsStatus.every((done) => done)
              ? 'Ready to Add Suppliers!'
              : 'Configure Your Search'}
          </h3>
        </Col>
        <Col>{right}</Col>
      </Row>
      <Row wrap={false} style={{ borderRadius: 2, overflow: 'hidden' }}>
        {stepsStatus.map((done, i) => (
          <Col
            key={i}
            style={{
              height: 6,
              flex: '1',
              background: done ? '#722ED1' : '#D3ADF7',
              marginRight: i + 1 === stepsStatus.length ? 0 : 3
            }}
          />
        ))}
      </Row>
    </div>
  );
}
StepsProgress.propTypes = {
  stepsStatus: PropTypes.arrayOf(PropTypes.bool),
  right: PropTypes.node
};

function Step({ children, title, disabled, selectSkip }) {
  return (
    <DisableableContent disabled={disabled}>
      {title ? (
        <Row className="mb-sm" align="middle">
          <Col flex="1">
            <h3 className="m-0">{title}</h3>
          </Col>
        </Row>
      ) : null}
      {children}
      {selectSkip && <Button onClick={() => selectSkip()}>Skip</Button>}
    </DisableableContent>
  );
}

Step.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  selectSkip: PropTypes.func,
  children: PropTypes.node
};
