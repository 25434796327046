import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './SpecficationItem.module.less';

function SpecficationItem({
  property = '',
  min = '',
  max = '',
  unit = '',
  method = ''
}) {
  return (
    <Row className={styles.specCart}>
      <Col span={9}>
        <Typography className={styles.property}>{property}</Typography>
      </Col>
      <Col span={3}>
        <Typography className={styles.spectItemText}>{min}</Typography>
      </Col>
      <Col span={3}>
        <Typography className={styles.spectItemText}>{max}</Typography>
      </Col>
      <Col span={3}>
        <Typography className={styles.spectItemText}>{unit}</Typography>
      </Col>
      <Col span={6}>
        <Typography className={styles.spectItemText}>{method}</Typography>
      </Col>
    </Row>
  );
}

SpecficationItem.propTypes = {
  property: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  unit: PropTypes.string,
  method: PropTypes.string
};

export default SpecficationItem;
