import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

function BuyerTestingAntdProvider({ children }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          controlHeight: 40,
          controlInteractiveSize: 16,
          borderRadius: 8
        },
        components: {
          Form: {
            itemMarginBottom: 24
          },
          Radio: {
            radioSize: 16,
            dotSize: 8
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  );
}

BuyerTestingAntdProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BuyerTestingAntdProvider;
