import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useAvailableUnitsForType } from 'src/utils/units';

function UnitsSelect({ unitType, value, onChange, per, ...props }) {
  const units = useAvailableUnitsForType(unitType);

  const onSelect = (v) => {
    if (onChange) onChange(v);
  };

  return (
    <Select
      defaultValue={value || undefined}
      options={(units || []).map((item) => ({
        label: `${per || ''}  ${item}`,
        value: item
      }))}
      onChange={onSelect}
      {...props}
    />
  );
}

UnitsSelect.propTypes = {
  unitType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  per: PropTypes.string
};

export default UnitsSelect;
