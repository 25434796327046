import { useQuery } from '@tanstack/react-query';
import { getNumericalPropertyTestingMethod } from 'src/Query';

// it is important to make this a constant or otherwise the hook returns a new instance of an empty array
// on every call
const DEFAULT_LOADING = [];

const useMetaNumericalPropertyTestingMethods = (codeOrUuid) => {
  const { data = DEFAULT_LOADING } = useQuery({
    queryKey: ['numerical-property-testing-method'],
    queryFn: () => getNumericalPropertyTestingMethod()
  });

  if (!codeOrUuid) return data || [];

  const testingMethods = data?.find(({ code, uuid }) =>
    [code, uuid].includes(codeOrUuid)
  );
  return testingMethods;
};

export default useMetaNumericalPropertyTestingMethods;
